@font-face {
  font-family: "strawfordregular";
  src: url("../../../html/fonts/strawford-regular-webfont.eot");
  src: url("../../../html/fonts/strawford-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/strawford-regular-webfont.woff2") format("woff2"), url("../../../html/fonts/strawford-regular-webfont.woff") format("woff"), url("../../../html/fonts/strawford-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sfiziaregularitalic";
  src: url("../../../html/fonts/Sfizia-RegularItalic.eot");
  src: url("../../../html/fonts/Sfizia-RegularItalic.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Sfizia-RegularItalic.woff2") format("woff2"), url("../../../html/fonts/Sfizia-RegularItalic.woff") format("woff"), url("../../../html/fonts/Sfizia-RegularItalic.ttf") format("truetype"), url("../../../html/fonts/Sfizia-RegularItalic.svg#Sfizia-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "sfiziaregular";
  src: url("../../../html/fonts/Sfizia-Regular.eot");
  src: url("../../../html/fonts/Sfizia-Regular.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Sfizia-Regular.woff2") format("woff2"), url("../../../html/fonts/Sfizia-Regular.woff") format("woff"), url("../../../html/fonts/Sfizia-Regular.ttf") format("truetype"), url("../../../html/fonts/Sfizia-Regular.svg#Sfizia-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "safiro";
  src: url("../../../html/fonts/Safiro-Medium.eot");
  src: url("../../../html/fonts/Safiro-Medium.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Safiro-Medium.woff2") format("woff2"), url("../../../html/fonts/Safiro-Medium.woff") format("woff"), url("../../../html/fonts/Safiro-Medium.ttf") format("truetype"), url("../../../html/fonts/Safiro-Medium.svg#Safiro-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
.notification-setting {
  position: absolute;
  top: 130px;
  right: 70px;
}
@media (max-width: 1600px) {
  .notification-setting {
    right: 60px;
  }
}
.notification-setting .icon {
  position: relative;
  margin-bottom: 35px;
  z-index: 0;
  box-shadow: 1px 5px 25px #767676;
}
@media (max-width: 1440px) {
  .notification-setting .icon {
    margin-bottom: 25px;
  }
}
.notification-setting .icon::after {
  content: "";
  display: block;
  background: #fff;
  -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  transform-origin: 0 0;
  box-shadow: 0px 0px 51.2999992371px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 50px;
  height: 43px;
  position: absolute;
  top: -14px;
  left: 32px;
  z-index: -1;
  transform: rotate(90deg);
}
@media (max-width: 1440px) {
  .notification-setting .icon::after {
    width: 35px;
    height: 28px;
    top: -10px;
    left: 20px;
  }
}
@media (max-width: 1440px) {
  .notification-setting .icon img {
    max-width: 15px;
  }
}
.notification-setting .icon:hover .info-show {
  opacity: 1;
}
.notification-setting .icon .info-show {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: -4px;
  right: 35px;
  background: #0F0F0F;
  color: #fff;
  border-radius: 5px;
  font-size: 10px;
  min-width: 75px;
  padding: 9px 5px;
  text-align: center;
}
.notification-setting .icon .info-show .arrow::after {
  content: "";
  position: absolute;
  top: 8px;
  right: -12px;
  z-index: 1;
  border: solid 7px transparent;
  border-right-color: #0f0f0f;
  transform: rotate(180deg);
}
.notification-setting .notification {
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  z-index: 1;
  top: -5px;
  right: -15px;
  font-family: "safiro", sans-serif;
}
.notification-setting .notification::before {
  content: "";
  background-color: #F4BF7D;
  -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  transform-origin: 0 0;
  box-shadow: 0px 0px 51.2999992371px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 20px;
  height: 18px;
  position: absolute;
  top: -1px;
  left: -4px;
  z-index: -1;
}/*# sourceMappingURL=Notification.css.map */