@font-face {
  font-family: "strawfordregular";
  src: url("../../../html/fonts/strawford-regular-webfont.eot");
  src: url("../../../html/fonts/strawford-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/strawford-regular-webfont.woff2") format("woff2"), url("../../../html/fonts/strawford-regular-webfont.woff") format("woff"), url("../../../html/fonts/strawford-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sfiziaregularitalic";
  src: url("../../../html/fonts/Sfizia-RegularItalic.eot");
  src: url("../../../html/fonts/Sfizia-RegularItalic.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Sfizia-RegularItalic.woff2") format("woff2"), url("../../../html/fonts/Sfizia-RegularItalic.woff") format("woff"), url("../../../html/fonts/Sfizia-RegularItalic.ttf") format("truetype"), url("../../../html/fonts/Sfizia-RegularItalic.svg#Sfizia-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "sfiziaregular";
  src: url("../../../html/fonts/Sfizia-Regular.eot");
  src: url("../../../html/fonts/Sfizia-Regular.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Sfizia-Regular.woff2") format("woff2"), url("../../../html/fonts/Sfizia-Regular.woff") format("woff"), url("../../../html/fonts/Sfizia-Regular.ttf") format("truetype"), url("../../../html/fonts/Sfizia-Regular.svg#Sfizia-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "safiro";
  src: url("../../../html/fonts/Safiro-Medium.eot");
  src: url("../../../html/fonts/Safiro-Medium.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Safiro-Medium.woff2") format("woff2"), url("../../../html/fonts/Safiro-Medium.woff") format("woff"), url("../../../html/fonts/Safiro-Medium.ttf") format("truetype"), url("../../../html/fonts/Safiro-Medium.svg#Safiro-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
.sidebar {
  background-color: #0f0f0f;
  max-width: 180px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-right: 30px;
  transition: all 0.2s ease-in-out;
  position: sticky;
  top: 0;
}
.sidebar .close-btn {
  margin-top: 30px;
  cursor: pointer;
}
.sidebar .logo {
  padding: 20px 0;
  width: 100%;
  margin-top: 8px;
}
@media (max-width: 1500px) {
  .sidebar .logo {
    padding: 20px 0;
  }
}
.sidebar .side-active {
  color: #fff;
}
.sidebar .side-active img {
  filter: grayscale(1);
}
.sidebar:not(.active) a:hover {
  color: #fff;
}
.sidebar:not(.active) a:hover img {
  filter: grayscale(1);
}
.sidebar.active {
  width: 60px;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 15px;
}
.sidebar.active .hide-text-m {
  display: none;
}
.sidebar.active .logo {
  overflow: hidden;
}
.sidebar.active .logo img {
  max-width: 150px;
}
@media (max-width: 1500px) {
  .sidebar.active .logo img {
    max-width: 140px;
    margin-left: 4px;
  }
}
@media (max-width: 1300px) {
  .sidebar.active .logo img {
    max-width: 120px;
  }
}
.sidebar.active a {
  position: relative;
}
.sidebar.active a:hover::after, .sidebar.active a.side-active::after {
  content: "";
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  width: 36px;
  height: 36px;
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: -1;
}
.sidebar.active a.side-active img {
  filter: brightness(0);
}
.sidebar.active a.side-active::after {
  background: rgb(217, 162, 93);
}
.sidebar.active .menu-title {
  padding-bottom: 12px;
  position: relative;
}
.sidebar.active .menu-title:before {
  content: "";
  background: #f4bf7d;
  width: 18px;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: -1px;
}
.sidebar.active .close-btn {
  transform: rotate(180deg);
  margin-left: -20px;
}
.sidebar.active .menu-btn {
  display: none;
}
.sidebar.active .submenu {
  padding: 0;
}
.sidebar.active .num {
  display: none;
}
.sidebar.active .profile-outer .profile-img {
  padding: 0;
}
.sidebar.active .profile-outer .profile-img .left-block img {
  max-width: 30px;
}
.sidebar.active .profile-outer .profile-img .left-block .profile-name {
  display: none;
}
.sidebar.active .profile-outer .profile-img .profile-btn {
  display: none;
}
.sidebar.active .profile-outer .profile-bottom-txt {
  display: none;
}
.sidebar .head {
  font-family: "sfiziaregularitalic", sans-serif;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  position: relative;
  padding-bottom: 8px;
  margin: 20px 0 12px;
}
.sidebar .head::after {
  content: "";
  background-color: #f4bf7d;
  height: 1px;
  width: 8px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.sidebar a {
  display: block;
  text-decoration: none;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  font-family: "strawfordregular", sans-serif;
  color: #f4bf7d;
}
@media (max-width: 1500px) {
  .sidebar a {
    font-size: 14px;
  }
}
@media (max-width: 1320px) {
  .sidebar a {
    font-size: 12px;
  }
}
.sidebar a.deactive {
  color: #6b6b6b;
}
.sidebar a .radio-btn {
  font-size: 11px;
  color: #6b6b6b;
}
.sidebar a img {
  margin-right: 2px;
  vertical-align: top;
  display: inline-block;
}
.sidebar .menu-title a {
  margin-bottom: 0;
}
.sidebar .submenu {
  padding: 0 10px 0 20px;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1500px) {
  .sidebar .submenu {
    padding: 0 0px 0 10px;
  }
}
@media (max-width: 1320px) {
  .sidebar .submenu {
    margin-top: 15px;
  }
}
.sidebar .submenu a {
  margin-top: 25px;
}
.sidebar .submenu.active {
  height: auto;
}
.sidebar .submenu .sub-head {
  display: flex;
  justify-content: space-between;
}
.sidebar .menu-btn {
  background: rgba(244, 191, 125, 0.1);
  border-radius: 50px;
  width: 40px;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: rotate(180deg);
}
@media (max-width: 1500px) {
  .sidebar .menu-btn {
    width: 20px;
    height: 20px;
  }
}
@media (max-width: 1320px) {
  .sidebar .menu-btn {
    width: 15px;
    height: 15px;
  }
}
.sidebar .menu-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  cursor: pointer;
}
.sidebar .menu-title.active .menu-btn {
  transform: rotate(0);
}
.sidebar .profile-outer {
  margin-bottom: 50px;
}
.sidebar .profile-outer .profile-img {
  color: #6b6b6b;
  font-size: 13.98px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: #191919;
  border-radius: 8px;
  position: relative;
}
.sidebar .profile-outer .profile-img::after {
  content: "";
  background: url("../../../public/assets/img/gradient-line.png") 0 0 no-repeat;
  background-size: 100%;
  width: 29px;
  height: 2px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  margin: auto;
}
.sidebar .profile-outer .profile-img .left-block {
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 1500px) {
  .sidebar .profile-outer .profile-img .left-block img {
    max-width: 30px;
  }
}
.sidebar .profile-outer .profile-img .profile-btn span {
  background: #6b6b6b;
  border-radius: 50px;
  width: 3px;
  height: 3px;
  display: block;
  margin: 3px 0;
}
.sidebar .profile-outer .profile-bottom-txt {
  text-align: center;
  font-size: 14px;
  color: #fff;
  margin-top: 40px;
}/*# sourceMappingURL=SideBar.css.map */