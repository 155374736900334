@font-face {
  font-family: "strawfordregular";
  // src: url('../../../fonts/strawford-regular-webfont.eot');
  src: url("../../../html/fonts/strawford-regular-webfont.eot");
  src: url("../../../html/fonts/strawford-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../../html/fonts/strawford-regular-webfont.woff2") format("woff2"),
    url("../../../html/fonts/strawford-regular-webfont.woff") format("woff"),
    url("../../../html/fonts/strawford-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sfiziaregularitalic";
  src: url("../../../html/fonts/Sfizia-RegularItalic.eot");
  src: url("../../../html/fonts/Sfizia-RegularItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../../../html/fonts/Sfizia-RegularItalic.woff2") format("woff2"),
    url("../../../html/fonts/Sfizia-RegularItalic.woff") format("woff"),
    url("../../../html/fonts/Sfizia-RegularItalic.ttf") format("truetype"),
    url("../../../html/fonts/Sfizia-RegularItalic.svg#Sfizia-RegularItalic")
      format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "sfiziaregular";
  src: url("../../../html/fonts/Sfizia-Regular.eot");
  src: url("../../../html/fonts/Sfizia-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../../../html/fonts/Sfizia-Regular.woff2") format("woff2"),
    url("../../../html/fonts/Sfizia-Regular.woff") format("woff"),
    url("../../../html/fonts/Sfizia-Regular.ttf") format("truetype"),
    url("../../../html/fonts/Sfizia-Regular.svg#Sfizia-RegularItalic")
      format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "safiro";
  src: url("../../../html/fonts/Safiro-Medium.eot");
  src: url("../../../html/fonts/Safiro-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../../../html/fonts/Safiro-Medium.woff2") format("woff2"),
    url("../../../html/fonts/Safiro-Medium.woff") format("woff"),
    url("../../../html/fonts/Safiro-Medium.ttf") format("truetype"),
    url("../../../html/fonts/Safiro-Medium.svg#Safiro-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.sidebar {
  background-color: #0f0f0f;
  max-width: 180px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-right: 30px;
  transition: all 0.2s ease-in-out;
  position: sticky;
  top: 0;
  .close-btn {
    margin-top: 30px;
    cursor: pointer;
  }
  .logo {
    padding: 20px 0;
    width: 100%;
    margin-top: 8px;
    @media (max-width: 1500px) {
      padding: 20px 0;
    }
  }
  .side-active {
    color: #fff;
    img {
      filter: grayscale(1);
    }
  }
  &:not(.active) {
    a {
      &:hover {
        color: #fff;
        img {
          filter: grayscale(1);
        }
      }
    }
  }
  &.active {
    width: 60px;
    overflow: hidden;
    padding-left: 10px;
    padding-right: 15px;
    .hide-text-m {
      display: none;
    }
    .logo {
      overflow: hidden;
      img {
        max-width: 150px;
        @media (max-width:1500px) {
          max-width: 140px;
          margin-left: 4px;
        }
        @media (max-width:1300px) {
          max-width: 120px;
        }
      }
    }
    a {
      position: relative;
      &:hover,
      &.side-active {
        &::after {
          content: "";
          background: rgba(255, 255, 255, 0.3);
          border-radius: 50px;
          width: 36px;
          height: 36px;
          position: absolute;
          top: -10px;
          left: -10px;
          z-index: -1;
        }
      }
      &:focus {
        img {
          // filter: brightness(0);
        }
        &::after {
          // background: rgb(217 162 93 / 100%);
        }
      }
      &.side-active {
        img {
          filter: brightness(0);
        }
        &::after {
          background: rgb(217 162 93 / 100%);
        }
      }
    }
    .menu-title {
      padding-bottom: 12px;
      position: relative;
      &:before {
        content: '';
        background: #f4bf7d;
        width: 18px;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: -1px;
      }
    }
    .close-btn {
      transform: rotate(180deg);
      margin-left: -20px;
    }
    .menu-btn {
      display: none;
    }
    .submenu {
      padding: 0;
    }
    .num {
      display: none;
    }
    .profile-outer {
      .profile-img {
        padding: 0;
        .left-block {
          img {
            max-width: 30px;
          }
          .profile-name {
            display: none;
          }
        }
        .profile-btn {
          display: none;
        }
      }
      .profile-bottom-txt {
        display: none;
      }
    }
  }
  .head {
    font-family: "sfiziaregularitalic", sans-serif;
    color: #fff;
    font-size: 12px;
    display: inline-block;
    position: relative;
    padding-bottom: 8px;
    margin: 20px 0 12px;
    &::after {
      content: "";
      background-color: #f4bf7d;
      height: 1px;
      width: 8px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  a {
    display: block;
    text-decoration: none;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
    font-family: "strawfordregular", sans-serif;
    color: #f4bf7d;
    @media (max-width: 1500px) {
      font-size: 14px;
    }
    @media (max-width: 1320px) {
      font-size: 12px;
    }
    &.active {
      // color: #f4bf7d;
      // .radio-btn {
      //   color: #f4bf7d;
      // }
    }
    &.deactive {
      color: #6b6b6b;
    }
    .radio-btn {
      font-size: 11px;
      color: #6b6b6b;
    }
    img {
      margin-right: 2px;
      vertical-align: top;
      display: inline-block;
    }
  }
  .menu-title {
    a {
      margin-bottom: 0;
    }
  }
  .submenu {
    padding: 0 10px 0 20px;
    // height: 0;
    // overflow: hidden;
    transition: all 0.3s ease-in-out;
    @media (max-width: 1500px) {
      padding: 0 0px 0 10px;
    }
    @media (max-width: 1320px) {
      margin-top: 15px;
    }
    a {
      margin-top: 25px;
    }
    &.active {
      height: auto;
    }
    .sub-head {
      display: flex;
      justify-content: space-between;
      // @media (max-width:1600px) {
      //     span {
      //         display: flex;
      //     }
      // }
    }
  }
  .menu-btn {
    background: rgb(244 191 125 / 10%);
    border-radius: 50px;
    width: 40px;
    height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transform: rotate(180deg);
    @media (max-width: 1500px) {
      width: 20px;
      height: 20px;
    }
    @media (max-width: 1320px) {
      width: 15px;
      height: 15px;
    }
  }
  .menu-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    cursor: pointer;
    &.active {
      .menu-btn {
        transform: rotate(0);
      }
    }
  }
  .profile-outer {
    margin-bottom: 50px;
    .profile-img {
      color: #6b6b6b;
      font-size: 13.98px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      background: #191919;
      border-radius: 8px;
      position: relative;
      &::after {
        content: "";
        background: url("../../../public/assets/img/gradient-line.png") 0 0
          no-repeat;
        background-size: 100%;
        width: 29px;
        height: 2px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -20px;
        margin: auto;
      }
      .left-block {
        display: flex;
        align-items: center;
        gap: 10px;
        img {
          @media (max-width: 1500px) {
            max-width: 30px;
          }
        }
      }
      .profile-btn {
        span {
          background: #6b6b6b;
          border-radius: 50px;
          width: 3px;
          height: 3px;
          display: block;
          margin: 3px 0;
        }
      }
    }
    .profile-bottom-txt {
      text-align: center;
      font-size: 14px;
      color: #fff;
      margin-top: 40px;
    }
  }
}
