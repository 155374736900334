@font-face {
    font-family: 'strawfordregular';
    // src: url('../../../fonts/strawford-regular-webfont.eot');
    src: url('../../../html/fonts/strawford-regular-webfont.eot');
    src: url('../../../html/fonts/strawford-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../html/fonts/strawford-regular-webfont.woff2') format('woff2'),
         url('../../../html/fonts/strawford-regular-webfont.woff') format('woff'),
         url('../../../html/fonts/strawford-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sfiziaregularitalic';
    src: url('../../../html/fonts/Sfizia-RegularItalic.eot');
    src: url('../../../html/fonts/Sfizia-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../../../html/fonts/Sfizia-RegularItalic.woff2') format('woff2'),
        url('../../../html/fonts/Sfizia-RegularItalic.woff') format('woff'),
        url('../../../html/fonts/Sfizia-RegularItalic.ttf') format('truetype'),
        url('../../../html/fonts/Sfizia-RegularItalic.svg#Sfizia-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'sfiziaregular';
    src: url('../../../html/fonts/Sfizia-Regular.eot');
    src: url('../../../html/fonts/Sfizia-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../../html/fonts/Sfizia-Regular.woff2') format('woff2'),
        url('../../../html/fonts/Sfizia-Regular.woff') format('woff'),
        url('../../../html/fonts/Sfizia-Regular.ttf') format('truetype'),
        url('../../../html/fonts/Sfizia-Regular.svg#Sfizia-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'safiro';
    src: url('../../../html/fonts/Safiro-Medium.eot');
    src: url('../../../html/fonts/Safiro-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../../html/fonts/Safiro-Medium.woff2') format('woff2'),
        url('../../../html/fonts/Safiro-Medium.woff') format('woff'),
        url('../../../html/fonts/Safiro-Medium.ttf') format('truetype'),
        url('../../../html/fonts/Safiro-Medium.svg#Safiro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


.font-8 {
    font-size: 8px;
}
.font-9 {
    font-size: 9px;
}
.font-10 {
    font-size: 10px;
}
.font-12 {
    font-size: 12px;
}
.font-13 {
    font-size: 13px;
}
.font-14 {
    font-size: 14px;
}
.font-16 {
    font-size: 16px;
    @media (max-width:1440px) {
        font-size: 14px;
    }
}
.font-17 {
    font-size: 17px;
    @media (max-width:1440px) {
        font-size: 14px;
    }
}
.font-25 {
    font-size: 25px;
    @media (max-width:1440px) {
        font-size: 18px;
    }
}
.big-font {
    font-size: 100px;
    @media (max-width:1440px) {
        font-size: 90px;
    }
    @media (max-width:1320px) {
        font-size: 80px;
    }
}
.gold {
    color: #D9A25D;
}
.red {
    color: #F78757;
}
.white {
    color: #fff;
}
.green {
    color: #5BC3A3;
}
.silver {
    color: #D2D2D2;
}
.margin-l-20 {
    margin-left: 20px;
}
.margin-l-10 {
    margin-left: 10px;
}
.margin-l-5 {
    margin-left: 5px;
}
.margin-lr-4 {
    margin: 0 4px;
}
.small-gap {
    margin: 0 2px;
}
select,.select-box,button,input,textarea {
    font-family: 'strawfordregular', sans-serif;
}
 
.four-column-box {
    width: 100%;
    gap: 15px;
    display: flex;
    flex-wrap: nowrap;
    @media (max-width:1440px) {
        gap: 10px;
    }
    @media (max-width:1320px) {
        gap: 7px;
    }
    .box-top-outer {
        height: 50px;
        .icon {
            position: relative;
            border: 1px #0F0F0F solid;
            color: #0F0F0F;
            width: 20px;
            height: 20px;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            @media (max-width:1440px) {
                width: 14px;
                height: 14px;
                font-size: 8px;
            }
            &:hover {
                .info-show {
                    display: block;
                    height: auto;
                    width: auto;
                }
            }
            svg {
                width: 12px;
            }
            .info-show {
                display: none;
                transition: all 0.3s ease-in-out;
                position: absolute;
                top: -4px;
                right: 26px;
                background: #ffffff;
                color: #0F0F0F;
                border-radius: 5px;
                font-size: 10px;
                min-width: 75px;
                padding: 9px 5px;
                text-align: center;
                .arrow {
                    &::after {
                        content: "";
                        position: absolute;
                        top: 8px;
                        right: -12px;
                        z-index: 1;
                        border: solid 7px transparent;
                        border-right-color: #fff;
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    .box-top {
        justify-content: space-between;
        span {
            font-family: 'safiro', sans-serif;
        }
    }
    .col-box {
        flex-basis: 25%;
    }
    .col-box-inner {
        background: #fff;
        box-shadow: 0px 0px 97.0999984741211px rgba(0, 0, 0, 0.05);
        padding: 20px;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        transition: all 0.2s ease-in-out;
        height: 100%;
        @media (max-width:1440px) {
            padding: 15px 10px;
        }
        &.active,&:hover {
            background-color: #0F0F0F;
            .dollar {
                color: #fff;
            }
            .big-font {
                color: #fff;
            }
            &::after {
                content: '';
                width: 147px;
                height: 172px;
                border-radius: 50%;
                position: absolute;
                top: 40px;
                left: -50px;
                background: #F4BF7D;
                box-shadow: 188.76083374023438px 188.76083374023438px 188.76083374023438px;
                border-radius: 9999px;
                filter: blur(70px);
            }
            .hexa-icon {
                background-image: url(../../../public//assets/img/hexagon-icon-gold.svg);
                // background: #F4BF7D;
                // border: #fff 2px solid;
            }        
            table {
                td {
                    color: #fff;
                    border-color: rgb(248 248 248 / 20%);
                }
            }
            .right {
                color: #fff;
            }
            .icon {
                border: 1px #fff solid;
                color: #fff;
            }
            svg {
                fill: #fff;
            }
            .hexa {
                fill: #F4BF7D;
                path {
                    stroke: #fff;
                }
            }
            .box-top {
                color: #fff;
            }
            .checkbox-box {
                color: #fff;
            }
        }
    }
    .hexa-icon {
        // background: #fff;
        // border: #0F0F0F 2px solid;
        // -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
        // clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
        // transform-origin: 0 0;
        // border-radius: 8px;
        position: relative;
        display: inline-block;
        background: url(../../../public//assets/img/hexagon-icon.svg) center bottom no-repeat;
        background-size: 100%;
        width: 18px;
        height: 16px;
        @media (max-width:1440px) {
            width: 13px;
            height: 13px;
        }
    }
    .number-box {
        font-family: 'safiro', sans-serif;
        margin: 20px 0 25px;
        position: relative;
        z-index: 1;
        .right {
            font-size: 34px;
            width: 100%;
            text-align: right;
            @media (max-width:1440px) {
                font-size: 24px;
            }
        }
        .big-font {
            margin-top: -15px;
        }
    }
    table {
        td {
            padding: 6px 0;
            border-bottom: 1px #F8F8F8 solid;
            font-size: 16px;
            font-family: "strawfordregular", sans-serif;
            @media (max-width:1440px) {
                font-size: 14px;
            }
            &:nth-child(2) {
                font-family: 'sfiziaregular', sans-serif;
            }
        }
        tr {
            &:last-child {
                td {
                    border-bottom: 0;
                }
            }
        }
    }
    .dollar {
        font-size: 20px;
        display: flex;
        align-items: flex-start;
    }
    .right {
        align-self: center;
    }
    .checkbox-box {
        position: relative;
        z-index: 1;
        margin-top: 14px;
        display: flex;
        color: #0F0F0F;
        font-size: 16px;
        .tgl {
            display: none;
        }
        .tgl + .tgl-btn:after {
            left: 3px;
        }
        .tgl + .tgl-btn:after, .tgl + .tgl-btn:before {
            position: absolute;
            top: 2px;
            display: block;
            content: "";
            width: 13px;
            height: 13px;
        }
        .tgl-light:checked + .tgl-btn {
            background: #5BC3A3;
        }
        .tgl:checked + .tgl-btn:after {
            left: 50%;
        }
        .tgl-light + .tgl-btn {
            background: #0F0F0F;
            border-radius: 2em;
            padding: 2px;
            transition: all 0.4s ease;
        }
        .tgl + .tgl-btn {
            outline: 0;
            display: block;
            width: 30px;
            height: 17px;
            position: relative;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            margin-right: 6px;
        }
        .tgl-light + .tgl-btn:after {
            border-radius: 50%;
            background: #fff;
            transition: all 0.2s ease;
        }
    }
}