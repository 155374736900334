
.chart-container {
  position: relative;
}

.custom-legend {
  list-style: none;
  padding: 0;
  margin: 0 0 10px 0; 
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.custom-legend li {
  display: flex;
  align-items: center; 
  margin-right: 10px; 
  cursor: pointer; 
}

.legend-icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 50%; 
}
.legendClass{
  
}
