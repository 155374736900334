@font-face {
  font-family: 'strawfordregular';
  // src: url('../../../fonts/strawford-regular-webfont.eot');
  src: url('../../../html/fonts/strawford-regular-webfont.eot');
  src: url('../../../html/fonts/strawford-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../../html/fonts/strawford-regular-webfont.woff2') format('woff2'),
    url('../../../html/fonts/strawford-regular-webfont.woff') format('woff'),
    url('../../../html/fonts/strawford-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
 
@font-face {
  font-family: 'sfiziaregularitalic';
  src: url('../../../html/fonts/Sfizia-RegularItalic.eot');
  src: url('../../../html/fonts/Sfizia-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('../../../html/fonts/Sfizia-RegularItalic.woff2') format('woff2'),
    url('../../../html/fonts/Sfizia-RegularItalic.woff') format('woff'),
    url('../../../html/fonts/Sfizia-RegularItalic.ttf') format('truetype'),
    url('../../../html/fonts/Sfizia-RegularItalic.svg#Sfizia-RegularItalic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
 
@font-face {
  font-family: 'sfiziaregular';
  src: url('../../../html/fonts/Sfizia-Regular.eot');
  src: url('../../../html/fonts/Sfizia-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../../html/fonts/Sfizia-Regular.woff2') format('woff2'),
    url('../../../html/fonts/Sfizia-Regular.woff') format('woff'),
    url('../../../html/fonts/Sfizia-Regular.ttf') format('truetype'),
    url('../../../html/fonts/Sfizia-Regular.svg#Sfizia-RegularItalic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
 
@font-face {
  font-family: 'safiro';
  src: url('../../../html/fonts/Safiro-Medium.eot');
  src: url('../../../html/fonts/Safiro-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../../html/fonts/Safiro-Medium.woff2') format('woff2'),
    url('../../../html/fonts/Safiro-Medium.woff') format('woff'),
    url('../../../html/fonts/Safiro-Medium.ttf') format('truetype'),
    url('../../../html/fonts/Safiro-Medium.svg#Safiro-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
 
.calendar-v1 {
  top: 38px !important;
  &.option-box {
    width: 730px;
    background: none !important;
    box-shadow: none !important;
    right: -70px !important;
  }
 
  .toggle-box {
    width: 100%;
    padding-right: 20px;
 
    .title-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
 
    .toggle-btn {
      cursor: pointer;
    }
 
    .filter-group {
      display: none;
    }
 
    &.active {
      .filter-group {
        display: block;
      }
    }
  }
 
  .input-date {
    font-size: 10px;
    color: #6B6B6B;
    input {
      position: relative;
      opacity: 1;
      height: auto;
      color: #6B6B6B;
      font-size: 12px;
      width: 75px;
    }
    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
    .mid-txt {
      margin: 0 8px 0 5px;
      color: #000;
      font-weight: 600;
    }
  }
 
  .sort-by-filter {
    .checkmark {
      background: #fff;
      border: 1px #667085 solid;
    }
 
    input {
      &:checked {
        ~.checkmark {
          border-color: #f3be7d;
        }
      }
    }
  }
 
  .calendar-container {
    display: flex;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
 
  .filters {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    flex-basis: 30%;
  }
 
  .calendar-box {
    flex-basis: 70%;
    border-left: 1px #F8F8F8 solid;
    padding-left: 30px;
  }
 
  .close-btn {
    cursor: pointer;
    background: url('../../../public//assets/img/close-btn.svg') 0 0 no-repeat;
    width: 24px;
    height: 24px;
    background-size: 100%;
    position: absolute;
    right: 20px;
    top: 20px;
  }
 
 
 
  .filter-title {
    font-size: 17px;
    margin-bottom: 10px;
    font-family: 'safiro', sans-serif;
    width: 100%;
 
    svg {
      margin-right: 3px;
      font-size: 14px;
    }
  }
 
  .filter-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    .sort-by-filter {
      margin-bottom: 5px;
    }
  }
 
  .filter-group input[type="checkbox"] {
    margin-right: 10px;
  }
 
  .react-calendar__month-view__weekdays__weekday abbr:where([title]) {
    color: #D2D2D2;
    text-decoration: none;
  }
 
  .react-calendar {
    width: 100%;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
  }
 
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    color: #000;
    border: none;
    font-size: 16px;
    margin-top: 8px;
    font-family: 'safiro', sans-serif;
  }
 
  .react-calendar__tile {
    background: #fff;
    color: #6B6B6B;
    border-radius: 4px;
    text-align: center;
    line-height: 0;
    font-size: 14px;
    border-radius: 50px;
    font-family: 'strawfordregular', sans-serif;
    width: 50px;
    height: 50px;
    position: relative;
    z-index: 1;
  }
 
  .react-calendar__tile--now {
    // background: #5BC3A3;
    // color: #fff;
  }
  .react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
    &::before {
      content: "";
      background: #F4BF7D;
      width: 40px;
      height: 40px;
      display: inline-block;
      z-index: -1;
      position: absolute;
      top: 4px;
      left: 12px;
      border-radius: 50px;
    }
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background-color: transparent;
    &::before {
      content: "";
      background: #F4BF7D;
      width: 40px;
      height: 40px;
      display: inline-block;
      z-index: -1;
      position: absolute;
      top: 4px;
      left: 12px;
      border-radius: 50px;
    }
  }

  .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
    background-color: transparent;
    &::before {
      content: "";
      background: #e6e6e6;
      width: 40px;
      height: 40px;
      display: inline-block;
      z-index: -1;
      position: absolute;
      top: 4px;
      left: 12px;
      border-radius: 50px;
    }
  }

  .react-calendar__year-view__months .react-calendar__tile:enabled:hover::before,
  .calendar-v1 .react-calendar__tile:enabled:focus::before,
  .react-calendar__decade-view .react-calendar__tile:enabled:hover::before,
  .calendar-v1 .react-calendar__tile:enabled:focus::before {
    content: "";
    background: #e6e6e6;
    width: 40px;
    height: 40px;
    display: inline-block;
    z-index: -1;
    position: absolute;
    top: 4px;
    left: 53px;
  }
 
  .react-calendar__tile--active {
    &::before {
      content: "";
      background: #F4BF7D;
      width: 40px;
      height: 40px;
      display: inline-block;
      z-index: -1;
      position: absolute;
      top: 4px;
      left: 12px;
      border-radius: 50px;
    }
  }
 
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus,
  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
    background: transparent;
    &::before {
      content: "";
      background: #F4BF7D;
      width: 40px;
      height: 40px;
      display: inline-block;
      z-index: -1;
      position: absolute;
      top: 4px;
      left: 12px;
      border-radius: 50px;
    }
    &::after {
      display: none;
    }

  }
 
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 1em 0.5em;
  }
 

  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day.highlighted,
  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day,
  .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend, .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day {
    background-color: transparent;
    overflow: visible !important;
    &::before {
      content: "";
      background: #F4BF7D;
      width: 40px;
      height: 40px;
      display: inline-block;
      z-index: -1;
      position: absolute;
      top: 4px;
      left: 12px;
      border-radius: 50px;
    }
    &::after {
      content: "";
      background-color: rgba(244, 191, 125, 0.2);
      width: 40px;
      height: 50px;
      display: inline-block;
      z-index: -1;
      position: absolute;
      top: 0px;
      right: 0px;
      border-radius: 20px 0 0 20px;
    }
  }

  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__month-view__days__day.highlighted,
  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__month-view__days__day {
    background-color: rgba(244, 191, 125, 0.2);
    // border-top: 1px #F4BF7D solid;
    // border-bottom: 1px #F4BF7D solid;
    border-radius: 0;
    &::before {
      display: none;
    }
  }

  .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day.highlighted,
  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend,
  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day,
  .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day, .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend, .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day {
    background-color: transparent;
    overflow: visible !important;
    &::before {
      content: "";
      background: #F4BF7D;
      width: 40px;
      height: 40px;
      display: inline-block;
      z-index: -1;
      position: absolute;
      top: 4px;
      left: 12px;
      border-radius: 50px;
    }
    &::after {
      content: "";
      background-color: rgba(244, 191, 125, 0.2);
      width: 41px;
      height: 50px;
      display: inline-block;
      z-index: -1;
      position: absolute;
      top: 0px;
      left: 0;
      border-radius: 0 20px 20px 0;
    }
  }


 
  .calendar-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }
 
  .cancel-button,
  .apply-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
 
  .cancel-button {
    color: #0F0F0F;
  }
 
  .apply-button {
    background-color: #0F0F0F;
    color: #fff;
    width: 200px;
    border-radius: 20px;
  }
}
.highlighted {
  background-color: #ccc; 
  border-radius: 50%;
  color: #333; 
}