*{
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0F0F0F;
  font-family: "strawfordregular";
}

code {
  font-family: "strawfordregular";
}

.orange{
  color: #F4BF7D;
}

@font-face {
  font-family:"strawfordregular" ;
  src: url(../public/assets/fonts/strawford-regular-webfont.woff) format('woff');
}

@font-face {
  font-family: 'Sfizia';
  src: url('../public/assets/fonts/Sfizia-Regular.woff2') format('woff2'),
      url('../public/assets/fonts/Sfizia-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Safiro-Medium';
  src: url('../public/assets/fonts/Safiro-Medium.woff2') format('woff2'),
      url('../public/assets/fonts/Safiro-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sfizia Regular';
  src: url('../public/assets/fonts/Sfizia-RegularItalic.woff2') format('woff2'),
      url('../public/assets/fonts/Sfizia-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
.gradient-text {
  background-image: linear-gradient(to right, #FFCA88, #2F2F2F,#2F2F2F, #FFCA88);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.fontSSafiro{
  font-family: 'Safiro-Medium';
}

.Sfizia{
  font-family: 'Sfizia Regular';
}
.nav_item {
  cursor: pointer;
}
.submenu {
  /* display: none; */
}
.active .submenu {
  display: block;

}
