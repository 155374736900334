@font-face {
  font-family: "strawfordregular";
  src: url("../../../html/fonts/strawford-regular-webfont.eot");
  src: url("../../../html/fonts/strawford-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/strawford-regular-webfont.woff2") format("woff2"), url("../../../html/fonts/strawford-regular-webfont.woff") format("woff"), url("../../../html/fonts/strawford-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sfiziaregularitalic";
  src: url("../../../html/fonts/Sfizia-RegularItalic.eot");
  src: url("../../../html/fonts/Sfizia-RegularItalic.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Sfizia-RegularItalic.woff2") format("woff2"), url("../../../html/fonts/Sfizia-RegularItalic.woff") format("woff"), url("../../../html/fonts/Sfizia-RegularItalic.ttf") format("truetype"), url("../../../html/fonts/Sfizia-RegularItalic.svg#Sfizia-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "sfiziaregular";
  src: url("../../../html/fonts/Sfizia-Regular.eot");
  src: url("../../../html/fonts/Sfizia-Regular.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Sfizia-Regular.woff2") format("woff2"), url("../../../html/fonts/Sfizia-Regular.woff") format("woff"), url("../../../html/fonts/Sfizia-Regular.ttf") format("truetype"), url("../../../html/fonts/Sfizia-Regular.svg#Sfizia-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "safiro";
  src: url("../../../html/fonts/Safiro-Medium.eot");
  src: url("../../../html/fonts/Safiro-Medium.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Safiro-Medium.woff2") format("woff2"), url("../../../html/fonts/Safiro-Medium.woff") format("woff"), url("../../../html/fonts/Safiro-Medium.ttf") format("truetype"), url("../../../html/fonts/Safiro-Medium.svg#Safiro-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
.data-top-header {
  background-color: #fff;
  position: sticky;
  display: none;
  top: 40px;
  width: 100%;
  left: 0;
  z-index: 2;
  padding: 7px 0;
  margin-top: 8px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.13);
  border-radius: 0;
}
.data-top-header::before {
  content: "";
  background: #fff url("../../../public/assets/img/header-patch-left.svg") 0 0 no-repeat;
  background-size: 100%;
  position: absolute;
  bottom: 0;
  left: -57px;
  width: 60px;
  height: 48px;
}
.data-top-header::after {
  content: "";
  background: #fff url("../../../public/assets/img/header-patch-left.svg") 0 0 no-repeat;
  background-size: 100%;
  position: absolute;
  bottom: 0;
  right: -86px;
  width: 90px;
  height: 48px;
}
.data-top-header.fixed {
  position: sticky;
  top: 60px;
  width: 100%;
  display: block;
}
.data-top-header .data-inside {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.data-top-header .data-inside .data-box {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  font-size: 15px;
}
.data-top-header .data-inside .data-box.active {
  background: #0F0F0F;
  color: #fff;
}
.data-top-header .data-inside .data-box i, .data-top-header .data-inside .data-box svg {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.9);
}

.topsecondheaderstickey {
  position: sticky;
  top: 50px;
  z-index: 2;
}/*# sourceMappingURL=StickeyNevbartop.css.map */