
.chartWrapper {
  position: relative;
  display: flex;
justify-content: space-between;
/* width:100%; */
width:1200px;
}

.chartWrapper > canvas {
  position: absolute;
  left: 100%;
  top: 100%;
  pointer-events: none;
}

.chartAreaWrapper {
  width: 100%;
  margin-left: 1px;
  /* width:100%; */
  /* width: 600px; */
  /* width: 50%;  */
  overflow-x: hidden;
}
.nik{
  width: 100%; 
  /* padding-bottom: 73px !important; */
}
/*this is dark mode css*/
.chart-container{
  background-color: black;
  color: white;
}

@media (min-width:1700px ) {
  /* .d-wrapper {
    width: 100%;
    padding: 0 80px;
  } */
}

@media (min-width:1600px ) {
  .chartWrapper {
    width:100%;
  }
  .d-wrapper {
    width: 100%;
    padding: 0 20px;
    width: calc(100% - 80px);
  }
}

@media (max-width:1600px ) {
  .chartWrapper {
    width:100%;
  }
  .d-wrapper {
    width: 100%;
    padding: 0 20px;
    width: calc(100% - 80px);
  }
}

