@font-face {
  font-family: "strawfordregular";
  src: url("../../../html/fonts/strawford-regular-webfont.eot");
  src: url("../../../html/fonts/strawford-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/strawford-regular-webfont.woff2") format("woff2"), url("../../../html/fonts/strawford-regular-webfont.woff") format("woff"), url("../../../html/fonts/strawford-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sfiziaregularitalic";
  src: url("../../../html/fonts/Sfizia-RegularItalic.eot");
  src: url("../../../html/fonts/Sfizia-RegularItalic.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Sfizia-RegularItalic.woff2") format("woff2"), url("../../../html/fonts/Sfizia-RegularItalic.woff") format("woff"), url("../../../html/fonts/Sfizia-RegularItalic.ttf") format("truetype"), url("../../../html/fonts/Sfizia-RegularItalic.svg#Sfizia-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "sfiziaregular";
  src: url("../../../html/fonts/Sfizia-Regular.eot");
  src: url("../../../html/fonts/Sfizia-Regular.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Sfizia-Regular.woff2") format("woff2"), url("../../../html/fonts/Sfizia-Regular.woff") format("woff"), url("../../../html/fonts/Sfizia-Regular.ttf") format("truetype"), url("../../../html/fonts/Sfizia-Regular.svg#Sfizia-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "safiro";
  src: url("../../../html/fonts/Safiro-Medium.eot");
  src: url("../../../html/fonts/Safiro-Medium.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Safiro-Medium.woff2") format("woff2"), url("../../../html/fonts/Safiro-Medium.woff") format("woff"), url("../../../html/fonts/Safiro-Medium.ttf") format("truetype"), url("../../../html/fonts/Safiro-Medium.svg#Safiro-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
.calendar-v1 {
  top: 38px !important;
}
.calendar-v1.option-box {
  width: 730px;
  background: none !important;
  box-shadow: none !important;
  right: -70px !important;
}
.calendar-v1 .toggle-box {
  width: 100%;
  padding-right: 20px;
}
.calendar-v1 .toggle-box .title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.calendar-v1 .toggle-box .toggle-btn {
  cursor: pointer;
}
.calendar-v1 .toggle-box .filter-group {
  display: none;
}
.calendar-v1 .toggle-box.active .filter-group {
  display: block;
}
.calendar-v1 .input-date {
  font-size: 10px;
  color: #6B6B6B;
}
.calendar-v1 .input-date input {
  position: relative;
  opacity: 1;
  height: auto;
  color: #6B6B6B;
  font-size: 12px;
  width: 75px;
}
.calendar-v1 .input-date input[type=date]::-webkit-inner-spin-button,
.calendar-v1 .input-date input[type=date]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.calendar-v1 .input-date .mid-txt {
  margin: 0 8px 0 5px;
  color: #000;
  font-weight: 600;
}
.calendar-v1 .sort-by-filter .checkmark {
  background: #fff;
  border: 1px #667085 solid;
}
.calendar-v1 .sort-by-filter input:checked ~ .checkmark {
  border-color: #f3be7d;
}
.calendar-v1 .calendar-container {
  display: flex;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.calendar-v1 .filters {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  flex-basis: 30%;
}
.calendar-v1 .calendar-box {
  flex-basis: 70%;
  border-left: 1px #F8F8F8 solid;
  padding-left: 30px;
}
.calendar-v1 .close-btn {
  cursor: pointer;
  background: url("../../../public//assets/img/close-btn.svg") 0 0 no-repeat;
  width: 24px;
  height: 24px;
  background-size: 100%;
  position: absolute;
  right: 20px;
  top: 20px;
}
.calendar-v1 .filter-title {
  font-size: 17px;
  margin-bottom: 10px;
  font-family: "safiro", sans-serif;
  width: 100%;
}
.calendar-v1 .filter-title svg {
  margin-right: 3px;
  font-size: 14px;
}
.calendar-v1 .filter-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}
.calendar-v1 .filter-group .sort-by-filter {
  margin-bottom: 5px;
}
.calendar-v1 .filter-group input[type=checkbox] {
  margin-right: 10px;
}
.calendar-v1 .react-calendar__month-view__weekdays__weekday abbr:where([title]) {
  color: #D2D2D2;
  text-decoration: none;
}
.calendar-v1 .react-calendar {
  width: 100%;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
}
.calendar-v1 .react-calendar__navigation button {
  min-width: 44px;
  background: none;
  color: #000;
  border: none;
  font-size: 16px;
  margin-top: 8px;
  font-family: "safiro", sans-serif;
}
.calendar-v1 .react-calendar__tile {
  background: #fff;
  color: #6B6B6B;
  border-radius: 4px;
  text-align: center;
  line-height: 0;
  font-size: 14px;
  border-radius: 50px;
  font-family: "strawfordregular", sans-serif;
  width: 50px;
  height: 50px;
  position: relative;
  z-index: 1;
}
.calendar-v1 .react-calendar__tile--now:enabled:hover::before, .calendar-v1 .react-calendar__tile--now:enabled:focus::before {
  content: "";
  background: #F4BF7D;
  width: 40px;
  height: 40px;
  display: inline-block;
  z-index: -1;
  position: absolute;
  top: 4px;
  left: 12px;
  border-radius: 50px;
}
.calendar-v1 .react-calendar__tile--now:enabled:hover,
.calendar-v1 .react-calendar__tile--now:enabled:focus {
  background-color: transparent;
}
.calendar-v1 .react-calendar__tile--now:enabled:hover::before,
.calendar-v1 .react-calendar__tile--now:enabled:focus::before {
  content: "";
  background: #F4BF7D;
  width: 40px;
  height: 40px;
  display: inline-block;
  z-index: -1;
  position: absolute;
  top: 4px;
  left: 12px;
  border-radius: 50px;
}
.calendar-v1 .react-calendar__tile:enabled:hover, .calendar-v1 .react-calendar__tile:enabled:focus {
  background-color: transparent;
}
.calendar-v1 .react-calendar__tile:enabled:hover::before, .calendar-v1 .react-calendar__tile:enabled:focus::before {
  content: "";
  background: #e6e6e6;
  width: 40px;
  height: 40px;
  display: inline-block;
  z-index: -1;
  position: absolute;
  top: 4px;
  left: 12px;
  border-radius: 50px;
}
.calendar-v1 .react-calendar__year-view__months .react-calendar__tile:enabled:hover::before,
.calendar-v1 .calendar-v1 .react-calendar__tile:enabled:focus::before,
.calendar-v1 .react-calendar__decade-view .react-calendar__tile:enabled:hover::before,
.calendar-v1 .calendar-v1 .react-calendar__tile:enabled:focus::before {
  content: "";
  background: #e6e6e6;
  width: 40px;
  height: 40px;
  display: inline-block;
  z-index: -1;
  position: absolute;
  top: 4px;
  left: 53px;
}
.calendar-v1 .react-calendar__tile--active::before {
  content: "";
  background: #F4BF7D;
  width: 40px;
  height: 40px;
  display: inline-block;
  z-index: -1;
  position: absolute;
  top: 4px;
  left: 12px;
  border-radius: 50px;
}
.calendar-v1 .react-calendar__tile--active:enabled:hover,
.calendar-v1 .react-calendar__tile--active:enabled:focus,
.calendar-v1 .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  background: transparent;
}
.calendar-v1 .react-calendar__tile--active:enabled:hover::before,
.calendar-v1 .react-calendar__tile--active:enabled:focus::before,
.calendar-v1 .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day::before {
  content: "";
  background: #F4BF7D;
  width: 40px;
  height: 40px;
  display: inline-block;
  z-index: -1;
  position: absolute;
  top: 4px;
  left: 12px;
  border-radius: 50px;
}
.calendar-v1 .react-calendar__tile--active:enabled:hover::after,
.calendar-v1 .react-calendar__tile--active:enabled:focus::after,
.calendar-v1 .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day::after {
  display: none;
}
.calendar-v1 .react-calendar__year-view .react-calendar__tile,
.calendar-v1 .react-calendar__decade-view .react-calendar__tile,
.calendar-v1 .react-calendar__century-view .react-calendar__tile {
  padding: 1em 0.5em;
}
.calendar-v1 .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day.highlighted,
.calendar-v1 .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day,
.calendar-v1 .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend, .calendar-v1 .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day {
  background-color: transparent;
  overflow: visible !important;
}
.calendar-v1 .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day.highlighted::before,
.calendar-v1 .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day::before,
.calendar-v1 .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend::before, .calendar-v1 .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day::before {
  content: "";
  background: #F4BF7D;
  width: 40px;
  height: 40px;
  display: inline-block;
  z-index: -1;
  position: absolute;
  top: 4px;
  left: 12px;
  border-radius: 50px;
}
.calendar-v1 .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day.highlighted::after,
.calendar-v1 .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day::after,
.calendar-v1 .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend::after, .calendar-v1 .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day::after {
  content: "";
  background-color: rgba(244, 191, 125, 0.2);
  width: 40px;
  height: 50px;
  display: inline-block;
  z-index: -1;
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 20px 0 0 20px;
}
.calendar-v1 .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__month-view__days__day.highlighted,
.calendar-v1 .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__month-view__days__day {
  background-color: rgba(244, 191, 125, 0.2);
  border-radius: 0;
}
.calendar-v1 .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__month-view__days__day.highlighted::before,
.calendar-v1 .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__month-view__days__day::before {
  display: none;
}
.calendar-v1 .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day.highlighted,
.calendar-v1 .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend,
.calendar-v1 .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day,
.calendar-v1 .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day, .calendar-v1 .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend, .calendar-v1 .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day {
  background-color: transparent;
  overflow: visible !important;
}
.calendar-v1 .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day.highlighted::before,
.calendar-v1 .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend::before,
.calendar-v1 .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day::before,
.calendar-v1 .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day::before, .calendar-v1 .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend::before, .calendar-v1 .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day::before {
  content: "";
  background: #F4BF7D;
  width: 40px;
  height: 40px;
  display: inline-block;
  z-index: -1;
  position: absolute;
  top: 4px;
  left: 12px;
  border-radius: 50px;
}
.calendar-v1 .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day.highlighted::after,
.calendar-v1 .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend::after,
.calendar-v1 .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day::after,
.calendar-v1 .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day::after, .calendar-v1 .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend::after, .calendar-v1 .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day::after {
  content: "";
  background-color: rgba(244, 191, 125, 0.2);
  width: 41px;
  height: 50px;
  display: inline-block;
  z-index: -1;
  position: absolute;
  top: 0px;
  left: 0;
  border-radius: 0 20px 20px 0;
}
.calendar-v1 .calendar-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}
.calendar-v1 .cancel-button,
.calendar-v1 .apply-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}
.calendar-v1 .cancel-button {
  color: #0F0F0F;
}
.calendar-v1 .apply-button {
  background-color: #0F0F0F;
  color: #fff;
  width: 200px;
  border-radius: 20px;
}

.highlighted {
  background-color: #ccc;
  border-radius: 50%;
  color: #333;
}/*# sourceMappingURL=CustomCalendar.css.map */