@font-face {
  font-family: "strawfordregular";
  src: url("../../../html/fonts/strawford-regular-webfont.eot");
  src: url("../../../html/fonts/strawford-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/strawford-regular-webfont.woff2") format("woff2"), url("../../../html/fonts/strawford-regular-webfont.woff") format("woff"), url("../../../html/fonts/strawford-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sfiziaregularitalic";
  src: url("../../../html/fonts/Sfizia-RegularItalic.eot");
  src: url("../../../html/fonts/Sfizia-RegularItalic.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Sfizia-RegularItalic.woff2") format("woff2"), url("../../../html/fonts/Sfizia-RegularItalic.woff") format("woff"), url("../../../html/fonts/Sfizia-RegularItalic.ttf") format("truetype"), url("../../../html/fonts/Sfizia-RegularItalic.svg#Sfizia-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "sfiziaregular";
  src: url("../../../html/fonts/Sfizia-Regular.eot");
  src: url("../../../html/fonts/Sfizia-Regular.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Sfizia-Regular.woff2") format("woff2"), url("../../../html/fonts/Sfizia-Regular.woff") format("woff"), url("../../../html/fonts/Sfizia-Regular.ttf") format("truetype"), url("../../../html/fonts/Sfizia-Regular.svg#Sfizia-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "safiro";
  src: url("../../../html/fonts/Safiro-Medium.eot");
  src: url("../../../html/fonts/Safiro-Medium.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Safiro-Medium.woff2") format("woff2"), url("../../../html/fonts/Safiro-Medium.woff") format("woff"), url("../../../html/fonts/Safiro-Medium.ttf") format("truetype"), url("../../../html/fonts/Safiro-Medium.svg#Safiro-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
.bottom-over-short {
  background: #0F0F0F;
  border-radius: 100px 100px 0 0;
  padding: 20px 30px;
  color: #fff;
  position: fixed;
  bottom: 30px;
  left: 230px;
  width: 80%;
  z-index: 2;
}
.bottom-over-short::before {
  content: "";
  background: url("../../../public/assets/img/header-patch-black.svg") 0 0 no-repeat;
  background-size: 100%;
  position: absolute;
  top: -3px;
  left: 0px;
  width: 60px;
  height: 60px;
  transform: rotate(90deg);
  z-index: -1;
}
.bottom-over-short::after {
  content: "";
  background: url("../../../public/assets/img/header-patch-black.svg") 0 0 no-repeat;
  background-size: 100%;
  position: absolute;
  top: 0;
  right: -3px;
  width: 62px;
  height: 62px;
  transform: rotate(180deg);
  z-index: -1;
}
.bottom-over-short .bottom-over-short-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
.bottom-over-short .bottom-over-short-inner::after {
  content: "";
  width: 147px;
  height: 172px;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  left: 150px;
  background: #F4BF7D;
  box-shadow: 188.7608337402px 188.7608337402px 188.7608337402px;
  filter: blur(80px);
  z-index: -1;
}
.bottom-over-short .bottom-over-short-inner li {
  list-style-type: none;
  position: relative;
  font-size: 15px;
  font-family: "safiro", sans-serif;
}
@media (max-width: 1320px) {
  .bottom-over-short .bottom-over-short-inner li {
    font-size: 14px;
  }
}
.bottom-over-short .bottom-over-short-inner li.active .hexa-icon {
  background-image: url("../../../public/assets/img/hexagon-icon-gold.svg");
}
.bottom-over-short .bottom-over-short-inner li .hexa-icon {
  background: url("../../../public/assets/img/white-hexa.svg") 0 0 no-repeat;
  background-size: 100%;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  display: inline-block;
  margin-right: 5px;
}
@media (max-width: 1320px) {
  .bottom-over-short .bottom-over-short-inner li .hexa-icon {
    width: 16px;
    height: 16px;
  }
}
.bottom-over-short .bottom-over-short-inner li .green {
  font-family: "strawfordregular", sans-serif;
}
.bottom-over-short .bottom-over-short-inner li .dollar {
  margin: 0 5px;
}
.bottom-over-short .bottom-over-short-inner .left-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bottom-over-short .bottom-over-short-inner .right-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
@media (max-width: 1320px) {
  .bottom-over-short .bottom-over-short-inner .right-block {
    gap: 20px;
  }
}/*# sourceMappingURL=dwnnevbar.css.map */