@font-face {
    font-family: 'strawfordregular';
    // src: url('../../../fonts/strawford-regular-webfont.eot');
    src: url('../../../html/fonts/strawford-regular-webfont.eot');
    src: url('../../../html/fonts/strawford-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../../html/fonts/strawford-regular-webfont.woff2') format('woff2'),
         url('../../../html/fonts/strawford-regular-webfont.woff') format('woff'),
         url('../../../html/fonts/strawford-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sfiziaregularitalic';
    src: url('../../../html/fonts/Sfizia-RegularItalic.eot');
    src: url('../../../html/fonts/Sfizia-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../../../html/fonts/Sfizia-RegularItalic.woff2') format('woff2'),
        url('../../../html/fonts/Sfizia-RegularItalic.woff') format('woff'),
        url('../../../html/fonts/Sfizia-RegularItalic.ttf') format('truetype'),
        url('../../../html/fonts/Sfizia-RegularItalic.svg#Sfizia-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'sfiziaregular';
    src: url('../../../html/fonts/Sfizia-Regular.eot');
    src: url('../../../html/fonts/Sfizia-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../../html/fonts/Sfizia-Regular.woff2') format('woff2'),
        url('../../../html/fonts/Sfizia-Regular.woff') format('woff'),
        url('../../../html/fonts/Sfizia-Regular.ttf') format('truetype'),
        url('../../../html/fonts/Sfizia-Regular.svg#Sfizia-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'safiro';
    src: url('../../../html/fonts/Safiro-Medium.eot');
    src: url('../../../html/fonts/Safiro-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../../html/fonts/Safiro-Medium.woff2') format('woff2'),
        url('../../../html/fonts/Safiro-Medium.woff') format('woff'),
        url('../../../html/fonts/Safiro-Medium.ttf') format('truetype'),
        url('../../../html/fonts/Safiro-Medium.svg#Safiro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
 
.dashboard-top-head {
    box-shadow: 0px 4px 16.899999618530273px rgba(96.70, 96.70, 96.70, 0.12);
    background-color: #fff;
    border-radius: 0 0 100px 100px;
    width: 100%;
    padding: 15px 30px;
    justify-content: space-between;
    position: relative;
    &::before {
        content: '';
        background: url('../../../public/assets/img/header-patch-left.svg') 0 0 no-repeat;
        background-size: 100%;
        position: absolute;
        bottom: 0;
        left: -4px;
        width: 54px;
        height: 54px;
    }
    &::after {
        content: '';
        background: url('../../../public/assets/img/header-patch-left.svg') 0 0 no-repeat;
        background-size: 100%;
        position: absolute;
        bottom: -3px;
        right: 1px;
        width: 54px;
        height: 54px;
        transform: rotate(270deg);
        z-index: 0;
    }
    @media (max-width:1440px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    .pick-call {
        position: relative;
        gap: 30px;
        z-index: 1;
        @media (max-width:1440px) {
            gap: 20px;
        }
    }
 
    .head-title {
        .gold {
            position: relative;
            padding-right: 14px;
            font-family: 'sfiziaregular', sans-serif;
            // background-image:"../../../public//assets/img/up-arr.png";
&::after {
                content: '';
                width: 1px;
                height: 10px;
                background-color: #0F0F0F;
                position: absolute;
                top: 3px;
                right: 5px;
            }
        }
        img {
            vertical-align: baseline;
            display: inline-block;
        }
    }
}
.topheaderstickey {
    position: sticky;
    top: 0;
    z-index: 3;
}
