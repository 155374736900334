@font-face {
  font-family: "strawfordregular";
  src: url("../../../html/fonts/strawford-regular-webfont.eot");
  src: url("../../../html/fonts/strawford-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/strawford-regular-webfont.woff2") format("woff2"), url("../../../html/fonts/strawford-regular-webfont.woff") format("woff"), url("../../../html/fonts/strawford-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sfiziaregularitalic";
  src: url("../../../html/fonts/Sfizia-RegularItalic.eot");
  src: url("../../../html/fonts/Sfizia-RegularItalic.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Sfizia-RegularItalic.woff2") format("woff2"), url("../../../html/fonts/Sfizia-RegularItalic.woff") format("woff"), url("../../../html/fonts/Sfizia-RegularItalic.ttf") format("truetype"), url("../../../html/fonts/Sfizia-RegularItalic.svg#Sfizia-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "sfiziaregular";
  src: url("../../../html/fonts/Sfizia-Regular.eot");
  src: url("../../../html/fonts/Sfizia-Regular.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Sfizia-Regular.woff2") format("woff2"), url("../../../html/fonts/Sfizia-Regular.woff") format("woff"), url("../../../html/fonts/Sfizia-Regular.ttf") format("truetype"), url("../../../html/fonts/Sfizia-Regular.svg#Sfizia-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "safiro";
  src: url("../../../html/fonts/Safiro-Medium.eot");
  src: url("../../../html/fonts/Safiro-Medium.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Safiro-Medium.woff2") format("woff2"), url("../../../html/fonts/Safiro-Medium.woff") format("woff"), url("../../../html/fonts/Safiro-Medium.ttf") format("truetype"), url("../../../html/fonts/Safiro-Medium.svg#Safiro-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
.column-chart-inner {
  background: #fff;
  box-shadow: 0px 0px 97.0999984741px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 0.86px white solid;
  padding: 20px;
  flex-basis: 25%;
}
@media (max-width: 1500px) {
  .column-chart-inner {
    padding: 15px;
  }
}
.column-chart-inner h4 {
  margin: 30px 0 20px;
  font-size: 20px;
  font-weight: 400;
}
.column-chart-inner h5 {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 6px;
  font-size: 13px;
  font-weight: 400;
}
.column-chart-inner h5::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 16px;
  height: 1px;
  background-color: #F4BF7D;
}
.column-chart-inner .c-list {
  margin-top: 20px;
}
.column-chart-inner .table {
  width: 100%;
}
.column-chart-inner .table .table-list {
  justify-content: space-between;
  border-bottom: 1px #efeeee solid;
  padding: 5px 0;
  font-size: 16px;
}
.column-chart-inner .table .table-list span:last-child {
  font-family: "sfiziaregular", sans-serif;
}

.production-chart-pop {
  display: block;
  box-shadow: 0px 4px 30.3999996185px rgba(34, 34, 34, 0.07);
  -webkit-backdrop-filter: blur(6.6px);
          backdrop-filter: blur(6.6px);
  width: 188px;
  padding: 10px 10px;
  position: absolute;
  border-radius: 10px;
  top: 200px;
  right: 10px;
  z-index: 9;
}
.production-chart-pop .data-box {
  padding: 6px 0 0;
}
.production-chart-pop .data-box:first-child {
  padding-top: 0;
  border-bottom: 1px #F8F8F8 solid;
}
.production-chart-pop .head {
  font-size: 16px;
}
.production-chart-pop .left {
  font-size: 13px;
}
.production-chart-pop .right {
  font-family: "sfiziaregular", sans-serif;
  font-size: 16px;
}
.production-chart-pop .gold-circ {
  position: relative;
  padding-left: 18px;
}
.production-chart-pop .gold-circ::before {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #F4BF7D;
  position: absolute;
  border-radius: 50px;
  left: 0;
  top: 2px;
}
.production-chart-pop .light-gold-circ {
  position: relative;
  padding-left: 18px;
}
.production-chart-pop .light-gold-circ::before {
  content: "";
  width: 13px;
  height: 13px;
  background-color: #F2E5D4;
  position: absolute;
  border-radius: 50px;
  left: 0;
  top: 2px;
}

.breakdown-pop-up-outer {
  position: relative;
}
.breakdown-pop-up-outer::after {
  content: "";
  clear: both;
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}
.breakdown-pop-up-outer .breakdown-pop-up {
  background-color: #fff;
  min-height: auto;
  max-width: 900px;
  margin: auto;
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  z-index: 4;
  border-radius: 20px;
}
.breakdown-pop-up-outer .breakdown-pop-up.active {
  display: block;
}
.breakdown-pop-up-outer .breakdown-pop-up::before {
  content: "";
  background: url("../../../public/assets/img/pattern-bg.png") 0 0 no-repeat;
  background-size: 100%;
  width: 400px;
  height: 500px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.breakdown-pop-up-outer .breakdown-pop-up::after {
  content: "";
  background: url("../../../public/assets/img/small-logo.png") 0 0 no-repeat;
  background-size: 100%;
  width: 33px;
  height: 36px;
  position: absolute;
  left: 30px;
  bottom: 30px;
  z-index: -1;
}
.breakdown-pop-up-outer .breakdown-pop-up .container-breakdown {
  padding: 22px 30px;
}
.breakdown-pop-up-outer .breakdown-pop-up .month-top-head {
  display: flex;
  justify-content: space-between;
}
.breakdown-pop-up-outer .breakdown-pop-up .month-top-head ul {
  display: flex;
  gap: 30px;
}
.breakdown-pop-up-outer .breakdown-pop-up .month-top-head ul li {
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-family: "safiro", sans-serif;
  color: #D2D2D2;
}
.breakdown-pop-up-outer .breakdown-pop-up .month-top-head ul li.active, .breakdown-pop-up-outer .breakdown-pop-up .month-top-head ul li:hover {
  text-decoration: underline;
  color: #0F0F0F;
}
.breakdown-pop-up-outer .breakdown-pop-up .close-btn {
  cursor: pointer;
  background: url("../../../public//assets/img/close-btn.svg") 0 0 no-repeat;
  width: 24px;
  height: 24px;
  background-size: 100%;
}
.breakdown-pop-up-outer .breakdown-pop-up .breakdown-table {
  max-width: 758px;
  margin: 4% auto;
}
.breakdown-pop-up-outer .breakdown-pop-up .breakdown-table tr th, .breakdown-pop-up-outer .breakdown-pop-up .breakdown-table tr td {
  text-align: left;
  font-weight: 400;
  font-family: "sfiziaregular", sans-serif;
  padding: 25px;
  line-height: 1.5;
  border: 1px #F8F8F8 solid;
}
.breakdown-pop-up-outer .breakdown-pop-up .breakdown-table tr th {
  color: #D9A25D;
  font-size: 17.76px;
}
.breakdown-pop-up-outer .breakdown-pop-up .breakdown-table tr td {
  font-size: 16px;
}
.breakdown-pop-up-outer .breakdown-pop-up .breakdown-table tr td:first-child {
  font-family: "strawfordregular", sans-serif;
}

.countries-table-pop {
  position: relative;
}
.countries-table-pop::after {
  content: "";
  clear: both;
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}
.countries-table-pop .table-pop-up {
  background-color: #fff;
  min-height: auto;
  max-width: 1380px;
  margin: auto;
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  z-index: 4;
  border-radius: 20px;
  padding: 24px;
}
.countries-table-pop .table-pop-up.active {
  display: block;
}
.countries-table-pop .table-pop-up .close-btn {
  cursor: pointer;
  background: url("../../../public//assets/img/close-btn.svg") 0 0 no-repeat;
  width: 24px;
  height: 24px;
  background-size: 100%;
}
.countries-table-pop .table-pop-up .head {
  display: flex;
  justify-content: space-between;
}
.countries-table-pop .table-pop-up .title {
  margin-right: 42px;
}
.countries-table-pop .table-pop-up .left-block {
  display: flex;
}
.countries-table-pop .table-pop-up .light-color {
  color: rgba(97, 97, 97, 0.3019607843);
}
.countries-table-pop .table-pop-up .checkbox-box {
  position: relative;
  z-index: 1;
  display: flex;
  color: #0F0F0F;
  font-size: 16px;
  margin-left: 10px;
}
.countries-table-pop .table-pop-up .checkbox-box .tgl {
  display: none;
}
.countries-table-pop .table-pop-up .checkbox-box .tgl + .tgl-btn:after {
  left: 3px;
}
.countries-table-pop .table-pop-up .checkbox-box .tgl + .tgl-btn:after, .countries-table-pop .table-pop-up .checkbox-box .tgl + .tgl-btn:before {
  position: absolute;
  top: 2px;
  display: block;
  content: "";
  width: 13px;
  height: 13px;
}
.countries-table-pop .table-pop-up .checkbox-box .tgl-light:checked + .tgl-btn {
  background: #CC00C1;
}
.countries-table-pop .table-pop-up .checkbox-box .tgl:checked + .tgl-btn:after {
  left: 60%;
}
.countries-table-pop .table-pop-up .checkbox-box .tgl-light + .tgl-btn {
  background: #0F0F0F;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
}
.countries-table-pop .table-pop-up .checkbox-box .tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 40px;
  height: 17px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  margin-right: 6px;
}
.countries-table-pop .table-pop-up .checkbox-box .tgl-light + .tgl-btn:after {
  border-radius: 50%;
  background: #fff;
  transition: all 0.2s ease;
}
.countries-table-pop .table-pop-up .table-data-box {
  margin-top: 24px;
}
.countries-table-pop .table-pop-up .table-data-box table {
  border: 1px #CACACA solid;
  border-radius: 4px;
}
.countries-table-pop .table-pop-up .table-data-box tr:nth-child(even) {
  background-color: #f3f3f3;
}
.countries-table-pop .table-pop-up .table-data-box tr:nth-child(2) {
  background-color: #ECEEF2;
}
.countries-table-pop .table-pop-up .table-data-box tr:nth-child(2) td {
  font-weight: 600;
}
.countries-table-pop .table-pop-up .table-data-box tr th, .countries-table-pop .table-pop-up .table-data-box tr td {
  font-size: 13px;
  padding: 10px 8px;
}
.countries-table-pop .table-pop-up .table-data-box tr th {
  color: #CC00C1;
  font-size: 16px;
  border: 1px #CACACA solid;
}
.countries-table-pop .table-pop-up .table-data-box tr td {
  text-align: left;
  color: #3E3D3D;
}
.countries-table-pop .table-pop-up .table-data-box tr td:first-child {
  border-right: 1px #CACACA solid;
  font-weight: 600;
}/*# sourceMappingURL=ProductCard.css.map */