@font-face {
  font-family: "strawfordregular";
  src: url("../../../html/fonts/strawford-regular-webfont.eot");
  src: url("../../../html/fonts/strawford-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/strawford-regular-webfont.woff2") format("woff2"), url("../../../html/fonts/strawford-regular-webfont.woff") format("woff"), url("../../../html/fonts/strawford-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sfiziaregularitalic";
  src: url("../../../html/fonts/Sfizia-RegularItalic.eot");
  src: url("../../../html/fonts/Sfizia-RegularItalic.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Sfizia-RegularItalic.woff2") format("woff2"), url("../../../html/fonts/Sfizia-RegularItalic.woff") format("woff"), url("../../../html/fonts/Sfizia-RegularItalic.ttf") format("truetype"), url("../../../html/fonts/Sfizia-RegularItalic.svg#Sfizia-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "sfiziaregular";
  src: url("../../../html/fonts/Sfizia-Regular.eot");
  src: url("../../../html/fonts/Sfizia-Regular.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Sfizia-Regular.woff2") format("woff2"), url("../../../html/fonts/Sfizia-Regular.woff") format("woff"), url("../../../html/fonts/Sfizia-Regular.ttf") format("truetype"), url("../../../html/fonts/Sfizia-Regular.svg#Sfizia-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "safiro";
  src: url("../../../html/fonts/Safiro-Medium.eot");
  src: url("../../../html/fonts/Safiro-Medium.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Safiro-Medium.woff2") format("woff2"), url("../../../html/fonts/Safiro-Medium.woff") format("woff"), url("../../../html/fonts/Safiro-Medium.ttf") format("truetype"), url("../../../html/fonts/Safiro-Medium.svg#Safiro-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
.strawford {
  font-family: "strawfordregular", sans-serif;
}

.safiro {
  font-family: "safiro", sans-serif;
}

.sfiziare {
  font-family: "sfiziaregular", sans-serif;
}

.sfiziaitalic {
  font-family: "sfiziaregularitalic", sans-serif;
}

.sign-container {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding: 0 80px;
}
@media (max-width: 1200px) {
  .sign-container {
    padding: 0 60px;
  }
}
@media (max-width: 991px) {
  .sign-container {
    padding: 0 30px;
  }
}
@media (max-width: 767px) {
  .sign-container {
    padding: 0 20px;
  }
}

.default-sin-in {
  min-height: 100vh;
  display: grid;
  align-items: center;
  padding: 80px 0 40px;
  background: var(--Grey-Shades-Grey-05, #0F0F0F);
  width: 100%;
}
@media (max-width: 1600px) {
  .default-sin-in {
    padding: 60px 0 30px;
  }
}
.default-sin-in input::-moz-placeholder, .default-sin-in textarea::-moz-placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.default-sin-in input::placeholder,
.default-sin-in textarea::placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.default-sin-in input::-webkit-input-placeholder,
.default-sin-in textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.default-sin-in input:-moz-placeholder,
.default-sin-in textarea:-moz-placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.default-sin-in input::-moz-placeholder,
.default-sin-in textarea::-moz-placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.default-sin-in input:-webkit-autofill, .default-sin-in input:-webkit-autofill:hover, .default-sin-in input:-webkit-autofill:focus, .default-sin-in input:-webkit-autofill:active, .default-sin-in textarea:-webkit-autofill, .default-sin-in textarea:-webkit-autofill:hover, .default-sin-in textarea:-webkit-autofill:focus, .default-sin-in textarea:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.2);
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px rgba(177, 177, 177, 0.1607843137);
}
.default-sin-in input[type=number]::-webkit-inner-spin-button,
.default-sin-in input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.default-sin-in .error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  display: inline-block;
}
.default-sin-in h1 {
  color: #FFCA88;
  font-family: "sfiziaregularitalic", sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 100px;
  line-height: 95px;
  margin-top: 50px;
}
@media (max-width: 1600px) {
  .default-sin-in h1 {
    font-size: 90px;
    line-height: 85px;
    margin-top: 30px;
  }
}
@media (max-width: 1440px) {
  .default-sin-in h1 {
    font-size: 80px;
    line-height: 75px;
    margin-top: 20px;
  }
}
@media (max-width: 991px) {
  .default-sin-in h1 {
    font-size: 60px;
    line-height: 55px;
  }
}
@media (max-width: 991px) {
  .default-sin-in h1 {
    font-size: 50px;
    line-height: 50px;
  }
}
.default-sin-in h1 .grad-text {
  background: -webkit-linear-gradient(#FFCA88, #2F2F2F);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-right: 10px;
}
@media (max-width: 1600px) {
  .default-sin-in .logo-icon img {
    max-width: 50px;
  }
}
@media (max-width: 1440px) {
  .default-sin-in .logo-icon img {
    max-width: 40px;
  }
}
@media (max-width: 991px) {
  .default-sin-in .logo-icon img {
    max-width: 30px;
  }
}
.default-sin-in .mid-align {
  display: flex;
  align-items: flex-start;
  padding: 0 100px;
}
@media (max-width: 1200px) {
  .default-sin-in .mid-align {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .default-sin-in .mid-align {
    flex-wrap: wrap;
  }
}
.default-sin-in .content-box {
  margin-top: 20px;
  padding-right: 20%;
}
@media (max-width: 1320px) {
  .default-sin-in .content-box {
    margin-top: 10px;
  }
}
@media (max-width: 991px) {
  .default-sin-in .content-box {
    padding-right: 10%;
  }
}
.default-sin-in .content-box p {
  color: #6B6B6B;
  font-size: 16px;
  line-height: 30px;
  font-family: "strawfordregular", sans-serif;
  font-style: normal;
  font-weight: 400;
}
@media (max-width: 1440px) {
  .default-sin-in .content-box p {
    font-size: 14px;
    line-height: 20px;
  }
}
.default-sin-in .left-content {
  padding-right: 100px;
  margin-top: 14%;
}
@media (max-width: 991px) {
  .default-sin-in .left-content {
    padding-right: 30px;
  }
}
@media (max-width: 767px) {
  .default-sin-in .left-content {
    flex-basis: 100%;
    padding-right: 0;
    margin-top: 0;
  }
}
.default-sin-in .left-content .members-box {
  margin-top: 60px;
}
.default-sin-in .left-content .members-box .title-box {
  display: flex;
  justify-content: space-between;
}
.default-sin-in .left-content .members-box .title-box h3 {
  font-family: "safiro", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.default-sin-in .left-content .members-box .members-list-box-outer .members-list-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--Grey-Shades-Grey-03, #6B6B6B);
  text-align: center;
  font-family: "strawfordregular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 17px;
  border-radius: 10px;
  background: #1C1C1C;
  box-shadow: 0px 0px 36.3px 0px rgba(0, 0, 0, 0.06);
  margin-top: 10px;
  text-align: left;
}
@media (max-width: 1440px) {
  .default-sin-in .left-content .members-box .members-list-box-outer .members-list-box {
    font-size: 14px;
    padding: 14px;
  }
}
@media (max-width: 767px) {
  .default-sin-in .left-content .members-box .members-list-box-outer .members-list-box {
    flex-direction: column;
    gap: 10px;
  }
}
.default-sin-in .left-content .members-box .members-list-box-outer .members-list-box:first-child {
  margin-top: 15px;
}
.default-sin-in .left-content .members-box .members-list-box-outer .members-list-box .name {
  color: #fff;
  text-transform: capitalize;
}
.default-sin-in .left-content .members-box .members-list-box-outer .members-list-box .icon-box-outer {
  display: flex;
  gap: 5px;
}
.default-sin-in .left-content .members-box .members-list-box-outer .members-list-box .icon-box {
  border-radius: 48px;
  background: var(--Grey-222, #262626);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.default-sin-in .left-content .members-box .members-list-box-outer .members-list-box .icon-box:hover {
  background: rgba(247, 135, 87, 0.2);
}
.default-sin-in .left-content .members-box .members-list-box-outer .members-list-box .icon-box:hover svg {
  color: #F78757;
}
.default-sin-in .left-content .members-box .members-list-box-outer .members-list-box .icon-box svg {
  color: #fff;
  font-size: 12px;
}
.default-sin-in .left-content .members-box .members-list-box-outer .members-list-box .members-inner {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .default-sin-in .left-content .members-box .members-list-box-outer .members-list-box .members-inner {
    justify-content: center;
  }
}
.default-sin-in .left-content .members-box .members-list-box-outer .members-list-box .members-inner.user-box {
  width: 150px;
  gap: 10px;
}
.default-sin-in .left-content .members-box .members-list-box-outer .members-list-box .members-inner.user-box img {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 767px) {
  .default-sin-in .left-content .members-box .members-list-box-outer .members-list-box .members-inner.user-box img {
    width: 20px;
    height: 20px;
  }
}
.default-sin-in .left-content .members-box .members-list-box-outer .members-list-box .members-inner.role-box {
  width: 110px;
}
.default-sin-in .left-content .members-box .members-list-box-outer .members-list-box .members-inner.mail-box {
  width: 200px;
}
@media (max-width: 767px) {
  .default-sin-in .right-content {
    flex-basis: 100%;
    margin-top: 30px;
  }
}
.default-sin-in .form-box {
  padding: 45px;
  border-radius: 8px;
  overflow: hidden;
  min-width: 405px;
  width: 100%;
  border: 1px solid rgba(139, 110, 73, 0.38);
  background: var(--Grey-Shades-Grey-05, #0F0F0F);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 0;
}
@media (max-width: 1320px) {
  .default-sin-in .form-box {
    padding: 30px;
    min-width: 330px;
  }
}
@media (max-width: 767px) {
  .default-sin-in .form-box {
    min-width: 100%;
    padding: 20px;
  }
}
.default-sin-in .form-box::before {
  content: "";
  border-radius: 333.501px;
  background: #896F4F;
  filter: blur(250px);
  width: 333.501px;
  height: 333.501px;
  display: inline-block;
  position: absolute;
  top: -130px;
  right: -200px;
  z-index: -1;
}
@media (max-width: 767px) {
  .default-sin-in .form-box::before {
    width: 200px;
    height: 200px;
    top: -70px;
    right: -15px;
  }
}
.default-sin-in .form-box::after {
  content: "";
  border-radius: 281.072px;
  background: var(--Client-Shades-Bee-Rev-Primary, #F4BF7D);
  filter: blur(250px);
  width: 281.072px;
  height: 281.072px;
  display: inline-block;
  position: absolute;
  bottom: -100px;
  z-index: -1;
}
@media (max-width: 767px) {
  .default-sin-in .form-box::after {
    width: 200px;
    height: 200px;
    bottom: -50px;
  }
}
.default-sin-in .form-box .form-start {
  position: relative;
  min-height: 70vh;
  padding-bottom: 120px;
}
.default-sin-in .form-box .back-link {
  color: var(--Client-Shades-Bee-Rev-Primary, #F4BF7D);
  font-family: "strawfordregular", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.default-sin-in .form-box .back-link svg {
  margin-right: 2px;
}
.default-sin-in .form-box h2 {
  color: #fff;
  font-size: 17px;
  font-family: "safiro", sans-serif;
  font-weight: 500;
  margin-bottom: 24px;
}
@media (max-width: 1320px) {
  .default-sin-in .form-box h2 {
    margin-bottom: 15px;
  }
}
.default-sin-in .form-box label {
  color: #fff;
  font-size: 16px;
  font-family: "strawfordregular", sans-serif;
  font-weight: 500;
}
@media (max-width: 1320px) {
  .default-sin-in .form-box label {
    font-size: 14px;
  }
}
.default-sin-in .form-box input, .default-sin-in .form-box textarea {
  border-radius: 36px;
  overflow: hidden;
  width: 100%;
  border: 0;
  color: rgba(255, 255, 255, 0.3);
  font-family: "strawfordregular", sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 40px 12px 15px;
  margin-top: 10px;
  outline: 0;
  background: var(--opacity-5, rgba(255, 255, 255, 0.05));
  resize: none;
  max-height: 50px;
}
@media (max-width: 1320px) {
  .default-sin-in .form-box input, .default-sin-in .form-box textarea {
    font-size: 14px;
    padding: 8px 40px 8px 15px;
    margin-top: 5px;
  }
}
.default-sin-in .form-box input[type=file] {
  display: none;
}
.default-sin-in .form-box .input-box {
  margin-top: 20px;
  position: relative;
}
@media (max-width: 1320px) {
  .default-sin-in .form-box .input-box {
    margin-top: 14px;
  }
}
.default-sin-in .form-box .input-box.with-text .input-icon {
  top: 117px;
}
@media (max-width: 1320px) {
  .default-sin-in .form-box .input-box.with-text .input-icon {
    top: 108px;
  }
}
@media (max-width: 767px) {
  .default-sin-in .form-box .input-box.with-text .input-icon {
    top: 110px;
  }
}
.default-sin-in .form-box .input-box .input-icon {
  color: #fff;
  position: absolute;
  top: 50px;
  right: 20px;
  font-size: 14px;
}
@media (max-width: 1440px) {
  .default-sin-in .form-box .input-box .input-icon {
    font-size: 12px;
  }
}
@media (max-width: 1320px) {
  .default-sin-in .form-box .input-box .input-icon {
    font-size: 9px;
    top: 43px;
  }
}
.default-sin-in .form-box .input-box.add-remove {
  text-align: center;
}
.default-sin-in .form-box .input-box.add-remove .number-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.default-sin-in .form-box .input-box.add-remove label {
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
.default-sin-in .form-box .input-box.add-remove input[type=number] {
  border-radius: 6px;
  background: var(--opacity-5, rgba(255, 255, 255, 0.05));
  width: 50%;
  text-align: center;
  margin-top: 0;
}
.default-sin-in .form-box .input-box.add-remove span {
  border-radius: 48px;
  opacity: 0.2;
  background: var(--Grey-Shades-Grey-05, #0F0F0F);
  width: 48px;
  height: 48px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  font-weight: 600;
  cursor: pointer;
}
.default-sin-in .form-box .input-box p {
  color: var(--Grey-Grey-02, #636363);
  font-family: "strawfordregular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.default-sin-in .form-box .two-col {
  display: flex;
  justify-content: space-between;
}
.default-sin-in .form-box .two-col .left h2 {
  margin-bottom: 10px;
}
.default-sin-in .form-box .two-col .left .icon-text .icon-box {
  border-radius: 50px;
  background: var(--opacity-5, rgba(255, 255, 255, 0.05));
  width: 28px;
  height: 28px;
  color: var(--Client-Shades-Bee-Rev-Secondary, #D9A25D);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
}
.default-sin-in .form-box .two-col .left .icon-text svg {
  font-size: 12px;
}
.default-sin-in .form-box .two-col .left .icon-text label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.default-sin-in .form-box .two-col .left .gold {
  font-family: "strawfordregular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.default-sin-in .form-box .two-col .right {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.default-sin-in .form-box .two-col .right .small-logo {
  position: relative;
  width: 100px;
  height: 100px;
}
.default-sin-in .form-box .two-col .right .small-logo img {
  position: absolute;
  top: -20px;
  right: -20px;
}
.default-sin-in .form-box .two-col .right .form-user {
  border-radius: 10px;
  background: var(--Grey-Shades-Grey-05, #0F0F0F);
  box-shadow: 0px 0px 42px 0px rgba(0, 0, 0, 0.12);
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.default-sin-in .form-box .two-col .right .form-user img {
  width: 30px;
  height: 30px;
  -o-object-fit: cover;
     object-fit: cover;
}
.default-sin-in .form-box .form-select-box-outer {
  margin-top: 30px;
}
.default-sin-in .form-box .form-select-box {
  border-radius: 36px;
  background: var(--opacity-5, rgba(255, 255, 255, 0.05));
  padding: 12px 15px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  margin-top: 10px;
}
@media (max-width: 1320px) {
  .default-sin-in .form-box .form-select-box {
    margin-top: 5px;
  }
}
.default-sin-in .form-box .form-select-box .selected-label {
  color: #D9A25D;
  text-align: center;
  font-family: "strawfordregular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 6px;
  background: rgba(244, 191, 125, 0.2);
  padding: 4px 6px;
}
.default-sin-in .form-box .form-select-box .title-box {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.default-sin-in .form-box .form-select-box .title-box .open-btn {
  background-color: #0F0F0F;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.default-sin-in .form-box .form-select-box .title-box .open-btn svg {
  vertical-align: middle;
  color: #fff;
  font-size: 14px;
}
.default-sin-in .form-box .form-select-box .select-box-option {
  border-radius: 17px;
  border: 0.753px solid #FFF;
  background: #FFF;
  box-shadow: 0px 23.35px 74.795px -5.273px rgba(0, 0, 0, 0.13);
  -webkit-backdrop-filter: blur(20.2239017487px);
          backdrop-filter: blur(20.2239017487px);
  padding: 10px 15px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 60px;
  z-index: 1;
}
.default-sin-in .form-box .form-select-box .select-box-option ul {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: flex;
  height: 150px;
  overflow-x: auto;
}
.default-sin-in .form-box .form-select-box .select-box-option ul::-webkit-scrollbar {
  width: 7px;
  border-radius: 37px;
}
.default-sin-in .form-box .form-select-box .select-box-option ul::-webkit-scrollbar-track {
  border-radius: 37px;
  background: var(--Grey-Shades-Grey-02, #D2D2D2);
}
.default-sin-in .form-box .form-select-box .select-box-option ul::-webkit-scrollbar-thumb {
  border-radius: 37px;
  background: var(--Grey-Shades-Grey-05, #0F0F0F);
}
.default-sin-in .form-box .form-select-box .select-box-option ul::-webkit-scrollbar-thumb:hover {
  background: #D9A25D;
}
.default-sin-in .form-box .form-select-box .select-box-option ul li, .default-sin-in .form-box .form-select-box .select-box-option ul label {
  padding: 4px 8px;
  color: #D9A25D;
  text-align: center;
  font-family: "strawfordregular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}
.default-sin-in .form-box .form-select-box .select-box-option ul li input, .default-sin-in .form-box .form-select-box .select-box-option ul label input {
  display: none;
}
.default-sin-in .form-box .form-select-box .select-box-option ul li input:checked + label, .default-sin-in .form-box .form-select-box .select-box-option ul label input:checked + label {
  color: #D9A25D;
  background: rgba(244, 191, 125, 0.2);
}
.default-sin-in .form-box .form-select-box .select-box-option ul li:hover {
  border-radius: 6px;
  background: rgba(244, 191, 125, 0.2);
}
.default-sin-in .form-box .form-select-box .select-box-option.open {
  display: block;
}
.default-sin-in .form-box .link-box {
  margin-top: 10px;
  color: var(--Grey-Shades-White, #FFF);
  font-family: "strawfordregular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.default-sin-in .form-box .submit-box {
  margin-top: 80px;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.default-sin-in .form-box .submit-box .add-btn {
  width: 100%;
  margin-top: 20px;
}
.default-sin-in .form-box .submit-box input[type=submit], .default-sin-in .form-box .submit-box button {
  border-radius: 154px;
  background: #0F0F0F;
  padding: 16px;
  color: #FFF;
  font-family: "safiro", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 1;
  border: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-color: #0F0F0F;
  color: #FFF;
}
.default-sin-in .form-box .submit-box input[type=submit]:hover:not(.default-sin-in .form-box .submit-box input[type=submit]:disabled, .disabled, .default-sin-in .form-box .submit-box button:disabled), .default-sin-in .form-box .submit-box button:hover:not(.default-sin-in .form-box .submit-box input[type=submit]:disabled, .disabled, .default-sin-in .form-box .submit-box button:disabled) {
  background-color: #d9a25d;
  color: #000;
}
.default-sin-in .form-box .submit-box input[type=submit]:disabled, .default-sin-in .form-box .submit-box button:disabled {
  opacity: 0.5 !important;
  cursor: auto;
  background-color: #0F0F0F !important;
  color: #fff;
}
@media (max-width: 1320px) {
  .default-sin-in .form-box .submit-box input[type=submit], .default-sin-in .form-box .submit-box button {
    padding: 14px;
    font-size: 15px;
  }
}
.default-sin-in .form-box .submit-box .link-bottom {
  text-align: center;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.default-sin-in .form-box .submit-box .link-bottom a {
  color: var(--Grey-Shades-White, #FFF);
  font-family: "strawfordregular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 20px;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  line-height: 1;
}
.default-sin-in .form-box .submit-box .link-bottom a:hover {
  text-decoration: underline;
}
.default-sin-in .form-box .submit-box .link-bottom a svg {
  vertical-align: middle;
  font-size: 12px;
}
.default-sin-in .form-box .submit-box .link-bottom .delete {
  color: #F78757;
}
.default-sin-in .form-box .submit-box .link-bottom .delete.disabled {
  color: var(--opacity-20, rgba(255, 255, 255, 0.2));
}
.default-sin-in .form-box .sign-in-list {
  margin-top: 20px;
}
.default-sin-in .form-box .sign-in-list li {
  color: #FFF;
  font-family: "strawfordregular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.2;
  margin-top: 5px;
  position: relative;
  padding-left: 25px;
}
@media (max-width: 1320px) {
  .default-sin-in .form-box .sign-in-list li {
    padding-left: 20px;
  }
}
.default-sin-in .form-box .sign-in-list li::after {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  background: url("../../../public/assets/img/circle-check.svg") 0 0 no-repeat;
  background-size: 100%;
  position: absolute;
  left: 0;
}
@media (max-width: 1320px) {
  .default-sin-in .form-box .sign-in-list li::after {
    width: 16px;
    height: 16px;
  }
}
.default-sin-in .form-box .sign-in-list li.active {
  opacity: 1;
}
.default-sin-in .form-box .sign-in-list li.active::before {
  background: url("../../../public/assets/img/circle-check-light.svg") 0 0 no-repeat;
}
@media (max-width: 1320px) {
  .default-sin-in .form-box .sign-in-list li {
    font-size: 14px;
  }
}

.form-footer-box {
  margin-top: 140px;
}
@media (max-width: 767px) {
  .form-footer-box {
    margin-top: 50px;
  }
}
.form-footer-box .form-footer-box-inner {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .form-footer-box .form-footer-box-inner {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
}
.form-footer-box .form-button-bottom {
  color: var(--Grey-Shades-Grey-03, #6B6B6B);
  text-align: center;
  font-family: "strawfordregular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 5px;
  background: rgba(37, 37, 37, 0.7);
  padding: 6px 8px;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.form-footer-box .form-button-bottom.b-active {
  color: #fff;
}
@media (max-width: 1440px) {
  .form-footer-box .form-button-bottom {
    font-size: 14px;
  }
}
.form-footer-box .form-button-bottom svg {
  font-size: 12px;
}
.form-footer-box .steps-counts {
  line-height: 1;
  display: flex;
  align-items: center;
  font-family: "safiro", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}
@media (max-width: 1320px) {
  .form-footer-box .steps-counts {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .form-footer-box .steps-counts {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
}
.form-footer-box .steps-counts .white {
  margin: 0 10px;
  color: var(--Grey-Shades-White, #FFF);
}
@media (max-width: 1320px) {
  .form-footer-box .steps-counts .white {
    margin: 0 5px;
  }
}
.form-footer-box .steps-counts .gold {
  position: relative;
  padding-right: 15px;
  margin-right: 15px;
}
@media (max-width: 1320px) {
  .form-footer-box .steps-counts .gold {
    padding-right: 8px;
    margin-right: 6px;
  }
}
.form-footer-box .steps-counts .gold::after {
  content: "";
  height: 11px;
  display: inline-block;
  background: #D2D2D2;
  position: absolute;
  width: 1px;
  top: 1px;
  right: 0;
}
.form-footer-box .steps-counts .steps-list {
  display: flex;
  gap: 10px;
}
@media (max-width: 1320px) {
  .form-footer-box .steps-counts .steps-list {
    gap: 4px;
  }
}
.form-footer-box .steps-counts .hexa-icon {
  background: url(../../../public/assets/img/light-hexa-icon.svg) 0 0 no-repeat;
  background-size: 100%;
  width: 14px;
  height: 12px;
  vertical-align: middle;
  display: inline-block;
}
@media (max-width: 1320px) {
  .form-footer-box .steps-counts .hexa-icon {
    width: 11px;
    height: 10px;
  }
}
.form-footer-box .steps-counts .hexa-icon.s-active {
  background-image: url(../../../public/assets/img/glow-hexa-icon.svg);
}

.default-sin-in.about-page {
  align-items: flex-start;
}
.default-sin-in.about-page .top-header {
  color: #DBDBDB;
  font-family: "strawfordregular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.default-sin-in.about-page h1 {
  font-size: 50px;
  vertical-align: middle;
  line-height: 1;
  margin-top: 90px;
}
.default-sin-in.about-page h1 img {
  max-width: 48px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: baseline;
}
.default-sin-in.about-page .top-links {
  display: flex;
  justify-content: space-between;
}
.default-sin-in.about-page .top-links a {
  color: var(--Grey-Shades-White, #FFF);
  font-family: "strawfordregular", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  gap: 10px;
}/*# sourceMappingURL=form.css.map */