
.avtivecard{
    background: #0F0F0F;
    color: white;
    position: relative;
    overflow: hidden;
}
.avtivecard::before{
    content: '';
    position: absolute;
    top: 25px;
    left: -50px;
    width: 150px;
    height: 150px;
    background: #F4BF7D;
    border-radius: 50%;
    filter: blur(80px);
}
.botomline{
    position: relative;
}
.botomline::before,.botomline::after{
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 50%;
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
    pointer-events: none;
}
.botomline:after {
    left: auto;
    right: 0;
    background: rgba(255, 255, 255, 0.1);
}
.h5{
    font-family: 'Sfizia Regular';
    font-size: 20px;
}
.cname{
    font-family: 'Safiro-Medium';
    font-size: 13px;
    position: relative;
}

.cname::after{
    content: '';
    display: block;
    width: 25px;
    height: 1px;
    background: #F4BF7D;
    margin: 5px 0;
   
}

.year{
    font-family: 'strawfordregular';
    font-size: 16px;
    position: relative;
    color: #0F0F0F;
}
.rev{
    font-family: 'Sfizia';
    font-size: 16px;
 
    position: relative;
    
}

.seprateline{
    position: relative;
    margin: 10px 0;
   
}

.seprateline::before,.seprateline::after{
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 50%;
    height: 1px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #dedede);
    pointer-events: none;
}
.seprateline:after {
    left: auto;
    right: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), #dedede);
}


