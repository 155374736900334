@font-face {
  font-family: "strawfordregular";
  src: url("../../../html/fonts/strawford-regular-webfont.eot");
  src: url("../../../html/fonts/strawford-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/strawford-regular-webfont.woff2") format("woff2"), url("../../../html/fonts/strawford-regular-webfont.woff") format("woff"), url("../../../html/fonts/strawford-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sfiziaregularitalic";
  src: url("../../../html/fonts/Sfizia-RegularItalic.eot");
  src: url("../../../html/fonts/Sfizia-RegularItalic.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Sfizia-RegularItalic.woff2") format("woff2"), url("../../../html/fonts/Sfizia-RegularItalic.woff") format("woff"), url("../../../html/fonts/Sfizia-RegularItalic.ttf") format("truetype"), url("../../../html/fonts/Sfizia-RegularItalic.svg#Sfizia-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "sfiziaregular";
  src: url("../../../html/fonts/Sfizia-Regular.eot");
  src: url("../../../html/fonts/Sfizia-Regular.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Sfizia-Regular.woff2") format("woff2"), url("../../../html/fonts/Sfizia-Regular.woff") format("woff"), url("../../../html/fonts/Sfizia-Regular.ttf") format("truetype"), url("../../../html/fonts/Sfizia-Regular.svg#Sfizia-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "safiro";
  src: url("../../../html/fonts/Safiro-Medium.eot");
  src: url("../../../html/fonts/Safiro-Medium.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Safiro-Medium.woff2") format("woff2"), url("../../../html/fonts/Safiro-Medium.woff") format("woff"), url("../../../html/fonts/Safiro-Medium.ttf") format("truetype"), url("../../../html/fonts/Safiro-Medium.svg#Safiro-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
.font-8 {
  font-size: 8px;
}

.font-9 {
  font-size: 9px;
}

.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}
@media (max-width: 1440px) {
  .font-16 {
    font-size: 14px;
  }
}

.font-17 {
  font-size: 17px;
}
@media (max-width: 1440px) {
  .font-17 {
    font-size: 14px;
  }
}

.font-25 {
  font-size: 25px;
}
@media (max-width: 1440px) {
  .font-25 {
    font-size: 18px;
  }
}

.big-font {
  font-size: 100px;
}
@media (max-width: 1440px) {
  .big-font {
    font-size: 90px;
  }
}
@media (max-width: 1320px) {
  .big-font {
    font-size: 80px;
  }
}

.gold {
  color: #D9A25D;
}

.red {
  color: #F78757;
}

.white {
  color: #fff;
}

.green {
  color: #5BC3A3;
}

.silver {
  color: #D2D2D2;
}

.margin-l-20 {
  margin-left: 20px;
}

.margin-l-10 {
  margin-left: 10px;
}

.margin-l-5 {
  margin-left: 5px;
}

.margin-lr-4 {
  margin: 0 4px;
}

.small-gap {
  margin: 0 2px;
}

select, .select-box, button, input, textarea {
  font-family: "strawfordregular", sans-serif;
}

.four-column-box {
  width: 100%;
  gap: 15px;
  display: flex;
  flex-wrap: nowrap;
}
@media (max-width: 1440px) {
  .four-column-box {
    gap: 10px;
  }
}
@media (max-width: 1320px) {
  .four-column-box {
    gap: 7px;
  }
}
.four-column-box .box-top-outer {
  height: 50px;
}
.four-column-box .box-top-outer .icon {
  position: relative;
  border: 1px #0F0F0F solid;
  color: #0F0F0F;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
@media (max-width: 1440px) {
  .four-column-box .box-top-outer .icon {
    width: 14px;
    height: 14px;
    font-size: 8px;
  }
}
.four-column-box .box-top-outer .icon:hover .info-show {
  display: block;
  height: auto;
  width: auto;
}
.four-column-box .box-top-outer .icon svg {
  width: 12px;
}
.four-column-box .box-top-outer .icon .info-show {
  display: none;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: -4px;
  right: 26px;
  background: #ffffff;
  color: #0F0F0F;
  border-radius: 5px;
  font-size: 10px;
  min-width: 75px;
  padding: 9px 5px;
  text-align: center;
}
.four-column-box .box-top-outer .icon .info-show .arrow::after {
  content: "";
  position: absolute;
  top: 8px;
  right: -12px;
  z-index: 1;
  border: solid 7px transparent;
  border-right-color: #fff;
  transform: rotate(180deg);
}
.four-column-box .box-top {
  justify-content: space-between;
}
.four-column-box .box-top span {
  font-family: "safiro", sans-serif;
}
.four-column-box .col-box {
  flex-basis: 25%;
}
.four-column-box .col-box-inner {
  background: #fff;
  box-shadow: 0px 0px 97.0999984741px rgba(0, 0, 0, 0.05);
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  transition: all 0.2s ease-in-out;
  height: 100%;
}
@media (max-width: 1440px) {
  .four-column-box .col-box-inner {
    padding: 15px 10px;
  }
}
.four-column-box .col-box-inner.active, .four-column-box .col-box-inner:hover {
  background-color: #0F0F0F;
}
.four-column-box .col-box-inner.active .dollar, .four-column-box .col-box-inner:hover .dollar {
  color: #fff;
}
.four-column-box .col-box-inner.active .big-font, .four-column-box .col-box-inner:hover .big-font {
  color: #fff;
}
.four-column-box .col-box-inner.active::after, .four-column-box .col-box-inner:hover::after {
  content: "";
  width: 147px;
  height: 172px;
  border-radius: 50%;
  position: absolute;
  top: 40px;
  left: -50px;
  background: #F4BF7D;
  box-shadow: 188.7608337402px 188.7608337402px 188.7608337402px;
  border-radius: 9999px;
  filter: blur(70px);
}
.four-column-box .col-box-inner.active .hexa-icon, .four-column-box .col-box-inner:hover .hexa-icon {
  background-image: url(../../../public//assets/img/hexagon-icon-gold.svg);
}
.four-column-box .col-box-inner.active table td, .four-column-box .col-box-inner:hover table td {
  color: #fff;
  border-color: rgba(248, 248, 248, 0.2);
}
.four-column-box .col-box-inner.active .right, .four-column-box .col-box-inner:hover .right {
  color: #fff;
}
.four-column-box .col-box-inner.active .icon, .four-column-box .col-box-inner:hover .icon {
  border: 1px #fff solid;
  color: #fff;
}
.four-column-box .col-box-inner.active svg, .four-column-box .col-box-inner:hover svg {
  fill: #fff;
}
.four-column-box .col-box-inner.active .hexa, .four-column-box .col-box-inner:hover .hexa {
  fill: #F4BF7D;
}
.four-column-box .col-box-inner.active .hexa path, .four-column-box .col-box-inner:hover .hexa path {
  stroke: #fff;
}
.four-column-box .col-box-inner.active .box-top, .four-column-box .col-box-inner:hover .box-top {
  color: #fff;
}
.four-column-box .col-box-inner.active .checkbox-box, .four-column-box .col-box-inner:hover .checkbox-box {
  color: #fff;
}
.four-column-box .hexa-icon {
  position: relative;
  display: inline-block;
  background: url(../../../public//assets/img/hexagon-icon.svg) center bottom no-repeat;
  background-size: 100%;
  width: 18px;
  height: 16px;
}
@media (max-width: 1440px) {
  .four-column-box .hexa-icon {
    width: 13px;
    height: 13px;
  }
}
.four-column-box .number-box {
  font-family: "safiro", sans-serif;
  margin: 20px 0 25px;
  position: relative;
  z-index: 1;
}
.four-column-box .number-box .right {
  font-size: 34px;
  width: 100%;
  text-align: right;
}
@media (max-width: 1440px) {
  .four-column-box .number-box .right {
    font-size: 24px;
  }
}
.four-column-box .number-box .big-font {
  margin-top: -15px;
}
.four-column-box table td {
  padding: 6px 0;
  border-bottom: 1px #F8F8F8 solid;
  font-size: 16px;
  font-family: "strawfordregular", sans-serif;
}
@media (max-width: 1440px) {
  .four-column-box table td {
    font-size: 14px;
  }
}
.four-column-box table td:nth-child(2) {
  font-family: "sfiziaregular", sans-serif;
}
.four-column-box table tr:last-child td {
  border-bottom: 0;
}
.four-column-box .dollar {
  font-size: 20px;
  display: flex;
  align-items: flex-start;
}
.four-column-box .right {
  align-self: center;
}
.four-column-box .checkbox-box {
  position: relative;
  z-index: 1;
  margin-top: 14px;
  display: flex;
  color: #0F0F0F;
  font-size: 16px;
}
.four-column-box .checkbox-box .tgl {
  display: none;
}
.four-column-box .checkbox-box .tgl + .tgl-btn:after {
  left: 3px;
}
.four-column-box .checkbox-box .tgl + .tgl-btn:after, .four-column-box .checkbox-box .tgl + .tgl-btn:before {
  position: absolute;
  top: 2px;
  display: block;
  content: "";
  width: 13px;
  height: 13px;
}
.four-column-box .checkbox-box .tgl-light:checked + .tgl-btn {
  background: #5BC3A3;
}
.four-column-box .checkbox-box .tgl:checked + .tgl-btn:after {
  left: 50%;
}
.four-column-box .checkbox-box .tgl-light + .tgl-btn {
  background: #0F0F0F;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
}
.four-column-box .checkbox-box .tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 30px;
  height: 17px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  margin-right: 6px;
}
.four-column-box .checkbox-box .tgl-light + .tgl-btn:after {
  border-radius: 50%;
  background: #fff;
  transition: all 0.2s ease;
}/*# sourceMappingURL=MetricCard.css.map */