@font-face {
  font-family: "strawfordregular";
  src: url("../../../html/fonts/strawford-regular-webfont.eot");
  src: url("../../../html/fonts/strawford-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/strawford-regular-webfont.woff2") format("woff2"), url("../../../html/fonts/strawford-regular-webfont.woff") format("woff"), url("../../../html/fonts/strawford-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sfiziaregularitalic";
  src: url("../../../html/fonts/Sfizia-RegularItalic.eot");
  src: url("../../../html/fonts/Sfizia-RegularItalic.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Sfizia-RegularItalic.woff2") format("woff2"), url("../../../html/fonts/Sfizia-RegularItalic.woff") format("woff"), url("../../../html/fonts/Sfizia-RegularItalic.ttf") format("truetype"), url("../../../html/fonts/Sfizia-RegularItalic.svg#Sfizia-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "sfiziaregular";
  src: url("../../../html/fonts/Sfizia-Regular.eot");
  src: url("../../../html/fonts/Sfizia-Regular.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Sfizia-Regular.woff2") format("woff2"), url("../../../html/fonts/Sfizia-Regular.woff") format("woff"), url("../../../html/fonts/Sfizia-Regular.ttf") format("truetype"), url("../../../html/fonts/Sfizia-Regular.svg#Sfizia-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "safiro";
  src: url("../../../html/fonts/Safiro-Medium.eot");
  src: url("../../../html/fonts/Safiro-Medium.eot?#iefix") format("embedded-opentype"), url("../../../html/fonts/Safiro-Medium.woff2") format("woff2"), url("../../../html/fonts/Safiro-Medium.woff") format("woff"), url("../../../html/fonts/Safiro-Medium.ttf") format("truetype"), url("../../../html/fonts/Safiro-Medium.svg#Safiro-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
.breadcrum-outer {
  margin-top: 50px;
  align-items: baseline;
  justify-content: space-between;
}
@media (max-width: 1500px) {
  .breadcrum-outer .head-right {
    padding-right: 30px;
  }
}
@media (max-width: 1400px) {
  .breadcrum-outer .head-right {
    padding-right: 40px;
  }
}
.breadcrum-outer .breadcrum a {
  text-decoration: none;
}
.breadcrum-outer .breadcrum .b-link {
  position: relative;
  padding-right: 36px;
}
@media (max-width: 1600px) {
  .breadcrum-outer .breadcrum .b-link {
    padding-right: 20px;
  }
}
.breadcrum-outer .breadcrum .b-link::after {
  content: "";
  position: absolute;
  top: 3px;
  right: 12px;
  background: url("../../../public//assets/img/link-arrow.svg") 0 0 no-repeat;
  background-size: 100%;
  width: 6px;
  height: 11px;
}
@media (max-width: 1600px) {
  .breadcrum-outer .breadcrum .b-link::after {
    right: 4px;
  }
}
.breadcrum-outer .breadcrum .last-updated {
  font-size: 10px;
  margin-left: 24px;
  color: #8D8D8D;
  font-family: "safiro", sans-serif;
}/*# sourceMappingURL=BreadCrumb.css.map */