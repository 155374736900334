.chartContainer {
  /* width:100% !important; */
  width:220px;
  margin: 0 auto;
  border-radius: 5px;
}
.highcharts-title{
  display:none;
}
.highcharts-credits{
  display: none;
}
.highcharts-xaxis-labels{
  font-size: 12px;
  font-weight: bold;
}
/* .corn {
    fill: #ffcc00; 
  }
  
  .wheat {
    fill: #996633; 
  } */
  