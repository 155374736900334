
@font-face {
    font-family: 'strawfordregular';
    // src: url('../../../fonts/strawford-regular-webfont.eot');
    src: url('../../html/fonts/strawford-regular-webfont.eot');
    src: url('../../html/fonts/strawford-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../html/fonts/strawford-regular-webfont.woff2') format('woff2'),
         url('../../html/fonts/strawford-regular-webfont.woff') format('woff'),
         url('../../html/fonts/strawford-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sfiziaregularitalic';
    src: url('../../html/fonts/Sfizia-RegularItalic.eot');
    src: url('../../html/fonts/Sfizia-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../../html/fonts/Sfizia-RegularItalic.woff2') format('woff2'),
        url('../../html/fonts/Sfizia-RegularItalic.woff') format('woff'),
        url('../../html/fonts/Sfizia-RegularItalic.ttf') format('truetype'),
        url('../../html/fonts/Sfizia-RegularItalic.svg#Sfizia-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'sfiziaregular';
    src: url('../../html/fonts/Sfizia-Regular.eot');
    src: url('../../html/fonts/Sfizia-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../html/fonts/Sfizia-Regular.woff2') format('woff2'),
        url('../../html/fonts/Sfizia-Regular.woff') format('woff'),
        url('../../html/fonts/Sfizia-Regular.ttf') format('truetype'),
        url('../../html/fonts/Sfizia-Regular.svg#Sfizia-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'safiro';
    src: url('../../html/fonts/Safiro-Medium.eot');
    src: url('../../html/fonts/Safiro-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../html/fonts/Safiro-Medium.woff2') format('woff2'),
        url('../../html/fonts/Safiro-Medium.woff') format('woff'),
        url('../../html/fonts/Safiro-Medium.ttf') format('truetype'),
        url('../../html/fonts/Safiro-Medium.svg#Safiro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
.column-chart-header {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    padding-bottom: 0;
    @media (max-width: 1440px) {
        padding: 20px;
        padding-bottom: 0;
    }
    .gold-circ {
        position: relative;
        padding-left: 18px;
        &::before {
            content: "";
            width: 13px;
            height: 13px;
            background-color: #F4BF7D;
            position: absolute;
            border-radius: 50px;
            left: 0;
            top: 2px;
        }
    }
    .light-gold-circ {
        position: relative;
        padding-left: 18px;
        &::before {
            content: "";
            width: 13px;
            height: 13px;
            background-color: #F2E5D4;
            position: absolute;
            border-radius: 50px;
            left: 0;
            top: 2px;
        }
    }
    .left-box {
        gap: 25px;
        display: flex;
        align-items: center;
    }    
}

.main-dashboard {
    min-height: 100vh;
}
 