@font-face {
  font-family: "strawfordregular";
  // src: url('../../../fonts/strawford-regular-webfont.eot');
  src: url("../../../html/fonts/strawford-regular-webfont.eot");
  src: url("../../../html/fonts/strawford-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../../html/fonts/strawford-regular-webfont.woff2") format("woff2"),
    url("../../../html/fonts/strawford-regular-webfont.woff") format("woff"),
    url("../../../html/fonts/strawford-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sfiziaregularitalic";
  src: url("../../../html/fonts/Sfizia-RegularItalic.eot");
  src: url("../../../html/fonts/Sfizia-RegularItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../../../html/fonts/Sfizia-RegularItalic.woff2") format("woff2"),
    url("../../../html/fonts/Sfizia-RegularItalic.woff") format("woff"),
    url("../../../html/fonts/Sfizia-RegularItalic.ttf") format("truetype"),
    url("../../../html/fonts/Sfizia-RegularItalic.svg#Sfizia-RegularItalic")
      format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "sfiziaregular";
  src: url("../../../html/fonts/Sfizia-Regular.eot");
  src: url("../../../html/fonts/Sfizia-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../../../html/fonts/Sfizia-Regular.woff2") format("woff2"),
    url("../../../html/fonts/Sfizia-Regular.woff") format("woff"),
    url("../../../html/fonts/Sfizia-Regular.ttf") format("truetype"),
    url("../../../html/fonts/Sfizia-Regular.svg#Sfizia-RegularItalic")
      format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "safiro";
  src: url("../../../html/fonts/Safiro-Medium.eot");
  src: url("../../../html/fonts/Safiro-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../../../html/fonts/Safiro-Medium.woff2") format("woff2"),
    url("../../../html/fonts/Safiro-Medium.woff") format("woff"),
    url("../../../html/fonts/Safiro-Medium.ttf") format("truetype"),
    url("../../../html/fonts/Safiro-Medium.svg#Safiro-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
.strawford {
  font-family: "strawfordregular", sans-serif;
}
.safiro {
  font-family: "safiro", sans-serif;
}
.sfiziare {
  font-family: "sfiziaregular", sans-serif;
}
.sfiziaitalic {
  font-family: "sfiziaregularitalic", sans-serif;
}

.high-chart-section {
  background: linear-gradient(
    214deg,
    rgba(255, 255, 255, 0.69) 100%,
    rgba(255, 255, 255, 0) 0%,
    rgba(254, 231, 202, 0.69) 100%
  );
  box-shadow: 0px 0px 97.0999984741211px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 0.86px white solid;
  margin-top: 30px;
  padding: 30px;
  .list {
    display: flex;
    gap: 25px;
    margin-left: 25px;
    align-items: center;
    @media (max-width: 1500px) {
      gap: 14px;
      margin-left: 15px;
    }
    .eye {
      cursor: pointer;
      &.toggle {
        .fa-eye-slash {
          display: none;
        }
        .fa-eye {
          display: inline-block;
        }
      }
      .fa-eye {
        // display: none;
      }
    }
    svg {
      color: #0f0f0f;
      font-size: 10px;
      margin: 0 0 0 5px;
      vertical-align: baseline;
    }
    span {
      .light-brown {
        &:last-child {
          margin-left: -12px;
        }
      }
    }
    .circ {
      width: 12px;
      height: 12px;
      border-radius: 50px;
      display: inline-block;
      margin-right: 5px;
    }
    .black-circ {
      background-color: #0f0f0f;
    }
    .light-brown {
      background-color: #f2e5d4;
    }
    .green-circ {
      background-color: #5bc3a3;
      margin-left: -10px;
    }
    .pink-circ {
      background-color: #f78757;
    }
    .green-pink-circ {
      width: 12px;
      height: 12px;
      border-radius: 50px;
      display: inline-block;
      background-color: #f78757;
      position: relative;
      vertical-align: middle;
      margin-right: 8px;
      &::after {
        content: '';
        background-color: #5bc3a3;
        width: 12px;
        height: 12px;
        border-radius: 50px;
        display: inline-block;
        position: relative;
        top: -1px;
        right: -7px;
      }
    }
    .gold-circ {
      background-color: #f4bf7d;
    }
    .brown-circ {
      background-color: #d9a25d;
    }
    .brown-hex {
      background: #f4bf7d;
      -webkit-clip-path: polygon(
        25% 5%,
        75% 5%,
        100% 50%,
        75% 95%,
        25% 95%,
        0% 50%
      );
      clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
      width: 12px;
      height: 12px;
      display: inline-block;
    }
    .dash-line {
      border-bottom: 2px dashed #d2d2d2;
      display: inline-block;
      width: 14px;
      vertical-align: top;
      border-radius: 0;
    }
  }
  .left-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.gold {
  color: #d9a25d;
}
.dis-flex {
  display: flex;
  flex-wrap: wrap;
}

.sort-by-filter {
  font-size: 16px;
  position: relative;
  .select-box {
    &.toggle {
      background-image: url("../../../public//assets/img/up-arr.png");
      + .option-box {
        display: block;
      }
    }
  }
  .option-box {
    display: none;
    position: absolute;
    background: white;
    box-shadow: 0px 23.349756240844727px 74.79454040527344px -5.272525787353516px rgba(0, 0, 0, 0.12);
    border-radius: 15px;
    padding: 10px;
    right: 5px;
    min-width: 124px;
    z-index: 1;
    top: 22px;
    &.jsCalendar {
      min-width: 320px;
      z-index: 2;
      tbody td.jsCalendar-current {
        background-color: #1f1c17;
      }
    }
    .option {
      display: block;
      margin-top: 8px;
      position: relative;
      &:first-child {
        margin-top: 0;
      }
    }
    .checkbox {
      margin-left: 24px;
      cursor: pointer;
    }
  }
  .gold {
    position: relative;
    padding-right: 15px;
    font-family: "sfiziaregular", sans-serif;
    line-height: 1;
    &::after {
      content: "";
      width: 1px;
      height: 10px;
      background-color: #0f0f0f;
      position: absolute;
      top: 3px;
      right: 5px;
    }
  }
  img {
    vertical-align: middle;
    display: inline-block;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark {
      background-color: #fff;
      &:after {
        display: block;
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: rgb(243 190 125 / 20%);
    border: 1px #f3be7d solid;
    border-radius: 5px;
    &:after {
      left: 5px;
      top: 1px;
      width: 4px;
      height: 8px;
      border: solid #f4bf7d;
      border-width: 0 1px 1px 0;
      transform: rotate(45deg);
    }
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
}


// Safari Start
_::-webkit-full-page-media, _:future, :root .sort-by-filter .gold::after {
  top: 6px;
}
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {
  .sort-by-filter .gold::after { 
    top: 6px;
  }
}}
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .sort-by-filter .gold::after { 
    top: 6px;
  }
}}
// Safari End

.select-box {
  background: transparent;
  border: 0;
  outline: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background: url("../../../public//assets/img/down-arr.svg") 0 0 no-repeat;
  background-position: right;
  padding-right: 18px;
  display: inline-block;
}
.period-pop-up {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  z-index: 9;
  position: absolute;
  top: -120px;
  box-shadow: 1px 1px 10px #9b9b9b;
  width: 270px;
  left: 0;
  right: 0;
  margin: auto;
  &.active {
    display: block;
  }
  .data-box {
    padding: 10px 0;
    border-bottom: 1px #f8f8f8 solid;
    justify-content: space-between;
  }
  .circ {
    width: 12px;
    height: 12px;
    border-radius: 50px;
    display: inline-block;
  }
  .black-circ {
    background-color: #0f0f0f;
  }
  .green-circ {
    background-color: #5bc3a3;
    margin-left: -4px;
  }
  .pink-circ {
    background-color: #f78757;
  }
  .brown-circ {
    background-color: #d9a25d;
  }
  .brown-hex {
    background: #d9a25d;
    -webkit-clip-path: polygon(
      25% 5%,
      75% 5%,
      100% 50%,
      75% 95%,
      25% 95%,
      0% 50%
    );
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    width: 12px;
    height: 12px;
    display: inline-block;
  }
  .dash-line {
    border-bottom: 2px dashed #d2d2d2;
    display: inline-block;
    width: 14px;
    vertical-align: middle;
  }
  .close-btn {
    cursor: pointer;
    background: url("../../../public//assets/img/close-btn.svg") 0 0 no-repeat;
    width: 24px;
    height: 24px;
    background-size: 100%;
  }
}

.dis-flex {
  display: flex;
  flex-wrap: wrap;
}
.content-between {
  justify-content: space-between;
}

.btn-default {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  background-color: #0f0f0f;
  padding: 12px 20px;
  border-radius: 15px;
  display: inline-block;
  text-decoration: none;
  width: 100%;
  margin-top: 30px;
}

.zoom-in-out-box {
  margin-top: 30px;
  padding: 0 20px 20px;
  .zoom-in-out-inner {
    display: flex;
    justify-content: space-between;
    i,
    svg {
      color: #0f0f0f;
      cursor: pointer;
    }
    .round-circ {
      background: rgba(250.64, 250.64, 250.64, 0.43);
      box-shadow: 0px 5px 36px rgba(0, 0, 0, 0.1);
      border-radius: 25.01px;
      border: 0.83px white solid;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .fa-magnifying-glass {
      font-size: 20px;
      position: relative;
      &::after {
        content: "\2b";
        clear: both;
        display: block;
        position: absolute;
        font-size: 8px;
        top: 13px;
        left: 14px;
      }
      &.minus {
        &::after {
          content: "\f068";
        }
      }
    }
    .slider {
      display: flex;
      align-items: center;
      gap: 20px;
      .slider-line {
        width: 307px;
        height: 6px;
        background-color: #d2d2d2;
        position: relative;
        span {
          background-color: #6b6b6b;
          display: inline-block;
          width: 275px;
          height: 6px;
          position: absolute;
          left: 15px;
        }
      }
    }
    .magnify {
      display: flex;
      gap: 10px;
    }
  }
}

.chart-dark-mode {
  &.chart-container {
    .period-pop-up {
      background-color: #0f0f0f;
    }
    .high-chart-section {
      background: none;
      background-color: #0f0f0f;
      border-color: #0f0f0f;
    }

    .zoom-in-out-box .zoom-in-out-inner i,
    .zoom-in-out-box .zoom-in-out-inner svg {
      color: #ffffff;
    }
    .sort-by-filter .option-box {
      background-color: #0f0f0f;
    }
  }
}


//  scroller funtion 
.highcharts-scrollbar-track {
  fill: #f1f1f1 !important;
  stroke: #f1f1f1 !important;
}

/* Customize the scrollbar thumb */
.highcharts-scrollbar-thumb {
  fill: #888 !important;
  stroke: #888 !important;
}

/* Customize the scrollbar thumb on hover */
.highcharts-scrollbar-thumb:hover {
  fill: #555 !important;
  stroke: #555 !important;
}

