.sidebar{
   
    width: 300px;
    color: white;
    min-height: 100vh;
}
.logo{
   
    width: 180px;
    height: auto;
    margin: 0 auto;
    display: block;
    padding: 20px;
}
.link{
    color: #F4BF7D ;
    text-decoration: none;
}
.nav_item {
    cursor: pointer;
}
.submenu {
    display: none;
}
.active .submenu {
    display: block;
}
